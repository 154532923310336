import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { containerVariants, MyAccountErrorInfo, MyAccountInfo } from "../../types/constant";
import iconCancel from "../../images/iconCancel.svg";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../images/angle_left.svg";
import "./MyAccount.scss";
import { TagButton, TagInput, TagMobileAlert, TagMobileDropDown, TagMobileToast, TagSwitch } from "@tag/tag-components-react-v3";
import { getAccountData, updateMyAccountData } from "../../api/api";
import { selectMyAccountInfo, setFirstName, setMiddleName, setLastName, setPhoneNumber, setFirstNameError, setMiddleNameError, setLastNameError, setPhoneNumberError } from "./MyAccountSlice";
import { useDispatch, useSelector } from "react-redux";


export default function MyAccount() {
	const navigate = useNavigate();
	const [showToast, setShowToast] = React.useState(false);
	const [myAccountData, setMyAccountData] = useState<any>([]);
	const [inputPushNotification, setInputPushNotification] = useState<any>(true);

	const dispatch = useDispatch();

	const [inputFirstName, setInputFirstName] = useState<any>("");
	const [inputMiddleName, setInputMiddleName] = useState<any>("");
	const [inputLastName, setInputLastName] = useState<any>("");
	const [inputPhoneNumber, setInputPhoneNumber] = useState<any>("");

	const title = React.useRef<any>(null);
	const firstNames = React.useRef<any>(null);
	const middleNames = React.useRef<any>(null);
	const lastName = React.useRef<any>(null);
	const phoneNumber = React.useRef<any>(null);
	
	const theAddMyAccountInfo = {} as MyAccountInfo;


	useEffect(() => {
		async function getMyAccountDetails() {
			try {
				const res = await getAccountData(1);
				if (Object.keys(res.data).length && res.status === 200) {
					// received api data successfully
					setMyAccountData(res.data);
				}
				
			} catch (err) {
				console.log("err:", err);
				return [];
			}
		}
		getMyAccountDetails();
	}, []);

	const formSubmit = () => {
		theAddMyAccountInfo.title = title.current.value ?? "";
		theAddMyAccountInfo.firstName = firstNames.current.value ?? "";
		theAddMyAccountInfo.middleName = middleNames.current.value ?? "";
		theAddMyAccountInfo.lastName = lastName.current.value ?? "";
		theAddMyAccountInfo.mobileNumber = phoneNumber.current.value ?? "";
		theAddMyAccountInfo.pushNotify = inputPushNotification ?? "";


		let isFirstNameValid = false;
		let isMiddleNameValid = false;
		let isLastNameValid = false;
		let isPhoneNumberValid = false;

		if (inputFirstName.length > 25 || myAccountData.firstName?.length > 25) {
			dispatch(setFirstNameError("First name should be less than 25 characters"))
		}
		else if (inputFirstName.length > 0) {
			const pattern = /^[a-zA-Z]+$/
			const result = pattern.test(firstNames.current.value);
			if (!result) {
				dispatch(setFirstNameError("Special characters are not allowed"))
			} else {
				isFirstNameValid = true;
				dispatch(setFirstNameError(""))
			}
		} else {
			isFirstNameValid = true;
			dispatch(setFirstNameError(""))
		}

		if (inputMiddleName.length > 25) {
			dispatch(setMiddleNameError("Middle name should be less than 25 characters"))
		} else if (inputMiddleName.length > 0) {
			const pattern = /^[a-zA-Z]+$/
			const result = pattern.test(middleNames.current.value);
			if (!result) {
				dispatch(setMiddleNameError("Special characters are not allowed"))
			} else {
				isMiddleNameValid = true;
				dispatch(setMiddleNameError(""))
			}
		} else {
			isMiddleNameValid = true;
			dispatch(setMiddleNameError(""))
		}



		if (inputLastName.length > 25) {
			dispatch(setLastNameError("Last name should be less than 25 characters"))
		}
		if (inputLastName.length > 0) {
			const pattern = /^[a-zA-Z]+$/
			const result = pattern.test(lastName.current.value);
			if (!result) {
				dispatch(setLastNameError("Special characters are not allowed"))
			} else {
				isLastNameValid = true;
				dispatch(setLastNameError(""))
			}
		} else {
			isLastNameValid = true;
			dispatch(setLastNameError(""))
		}


		if (myAccountData.mobileNumber?.length === 0 && inputPhoneNumber?.length === 0) {
			dispatch(setPhoneNumberError("Phone number is required"))
		} else {
			dispatch(setPhoneNumberError(""))
			const pattern = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/
			const result = pattern.test(phoneNumber.current.value);
			if (!result) {
				dispatch(setPhoneNumberError("Please enter valid phone number"))
			} else {
				isPhoneNumberValid = true;
				dispatch(setPhoneNumberError(""))
			}
		}

		const onSubmit = async (data: any) => {
			const res = await updateMyAccountData(data, 1);
			
			if(res.status === 200) {
				setShowToast(true);
				const timer = setTimeout(() => {
					navigate("/home");
				}, 1500);
				return () => clearTimeout(timer);
			}
		};

		if (isFirstNameValid && isPhoneNumberValid && isMiddleNameValid && isLastNameValid) {
			console.log("data:", theAddMyAccountInfo);
			onSubmit(theAddMyAccountInfo)
		}
	}
	function resetMyAccount() {
		dispatch(setFirstName(undefined));
		dispatch(setMiddleName(undefined));
		dispatch(setLastName(undefined));
		dispatch(setPhoneNumber(undefined));
		dispatch(setFirstNameError(""));
		dispatch(setMiddleNameError(""));
		dispatch(setLastNameError(""));
		dispatch(setPhoneNumberError(""));
		theAddMyAccountInfo.firstName = firstNames.current.value ?? "";
		theAddMyAccountInfo.middleName = middleNames.current.value ?? "";
		theAddMyAccountInfo.lastName = lastName.current.value ?? "";
		theAddMyAccountInfo.mobileNumber = phoneNumber.current.value ?? "";
		theAddMyAccountInfo.pushNotify = inputPushNotification ?? "";
	}



	//FETCH REDUX VALUES OF ERROR MESSAGES
	const { MyAccountError } = useSelector(selectMyAccountInfo);
	const error = MyAccountError as MyAccountErrorInfo
	
	const { handleSubmit } = useForm();

	return (
		<>
			<motion.div
				className="motionDiv"
				initial="hidden"
				animate="visible"
				exit="exit"
				variants={containerVariants}
			>
				<div className="myAccount_header">
					<div className="myAccount_header-back">
						<img
							src={arrowBack}
							className="myAccount_header-back-size"
							alt="Arrow Back"
							onClick={() => {
								navigate("/home"); 
								resetMyAccount()
							}}
						/>
					</div>
					<div className="myAccount_title" >My Account</div>
					<div className="myAccount_action">
						<img
							src={iconCancel}
							alt="Cancel icon"
							className="myAccount_action-size"
							onClick={() => {
								navigate("/home"); 
								resetMyAccount()
							}}
						/>
					</div>
				</div>

				<div className="myAccount_form">
					<form
						onSubmit={handleSubmit(formSubmit)}
						className="myAccount_form-wrapper"
					>
						<div className="myAccount_form-content">
							<label className="myAccount_form-label">Title</label>
							<div className="myAccount_form-inputControl">
								<div className="myAccount_form-input">
									<div className="myAccount_form-select">
										<TagMobileDropDown
											placeholder='Enter title here'
											value={myAccountData?.title}
											ref={title}
											data={[
												{ label: "Miss", value: "Miss" },
												{ label: "Mr", value: "Mr" },
												{ label: "Mrs", value: "Mrs" },
											]}
										/>
									</div>
								</div>
							</div>

							<label className="myAccount_form-label" id="firstName">First name</label>
							<div className="myAccount_form-inputControl">
								<div className={`myAccount_form-input ${error.FirstName ? "showErrorMessage" : ""}`}>
									<TagInput
										name='firstName'
										placeholder='Enter first name here'
										onChange={() => setInputFirstName(firstNames.current.value)}
										ref={firstNames}
										className="myAccount_form-input-box"
										autocomplete="off"
										value={myAccountData?.firstName ?? inputFirstName}
										// defaultValue={myAccountData?.firstName}
									/>

								</div>
								{error.FirstName && (
									<div className="errorMessage" role="alert">
										{error.FirstName}
									</div>
								)}
							</div>

							<label className="myAccount_form-label">Middle name</label>
							<div className="myAccount_form-inputControl">
								<div className={`myAccount_form-input ${error.MiddleName ? "showErrorMessage" : ""}`}>
									<TagInput
										name='middleName'
										placeholder='Enter middle name here'
										onChange={() => setInputMiddleName(middleNames.current.value)}
										ref={middleNames}
										className="myAccount_form-input-box"
										autocomplete="off"
										value={myAccountData?.middleName ?? inputMiddleName}
									/>
								</div>
								{error.MiddleName && (
									<div className="errorMessage" role="alert">
										{error.MiddleName}
									</div>
								)}
							</div>

							<label className="myAccount_form-label" id="lastName">Last name</label>
							<div className="myAccount_form-inputControl">
								<div className={`myAccount_form-input ${error.LastName ? "showErrorMessage" : ""}`}>
									<TagInput
										name='lastName'
										placeholder='Enter last name here'
										onChange={() => setInputLastName(lastName.current.value)}
										ref={lastName}
										className="myAccount_form-input-box"
										autocomplete="off"
										value={myAccountData?.lastName  ?? inputLastName}
									/>
								</div>
								{error.LastName && (
									<div className="errorMessage" role="alert">
										{error.LastName}
									</div>
								)}
							</div>

							<label className="myAccount_form-label">Phone number</label>
							<div className="myAccount_form-inputControl">
								<div className={`myAccount_form-input ${error.PhoneNumber ? "showErrorMessage" : ""}`}>
									<div className="myAccount_form-inputBox">
										<TagInput
											name='phoneNumber'
											placeholder='Enter phone number here'
											onChange={() => setInputPhoneNumber(phoneNumber.current.value)}
											ref={phoneNumber}
											className="myAccount_form-input-box"
											autocomplete="off"
											value={myAccountData?.mobileNumber ?? inputPhoneNumber}
										/>
									</div>
									{error.PhoneNumber && (
										<div className="errorMessage" role="alert">
											{error.PhoneNumber}
										</div>
									)}
								</div>
							</div>

							<label className="myAccount_form-label">
								Allow Push Notifications
							</label>
							<div className="myAccount_form-input">
								<TagSwitch
									size="normal"
									labelPlacement="right"
									name="Push"
									checked={myAccountData?.pushNotify}
									//checked
									accent="navy"
									defaultValue={myAccountData?.pushNotify  ?? inputPushNotification}
									onSwitchValueChange={(e) => setInputPushNotification(e.detail.checked)}
								></TagSwitch>
							</div>
						</div>
						<div className="myAccount_form-button full-width__button">
							<TagButton
								type="submit"
								device="mobile"
								text="Save"
								accent="navy"
								className="customButton"
								role="button"
							></TagButton>
						</div>
					</form>
				</div>
			</motion.div>

			<div className={showToast ? "z-indexed" : ""}>
				{showToast ? (
					<>
						<TagMobileToast position='top'>
							<TagMobileAlert
								size="fullWidth"
								showDuration={1500}
								closeable
								closeTransition='slideUp'
							>
								<div className="div_alert">
									<span className="span_alert">
										Your data updated successfully.
									</span>
								</div>
							</TagMobileAlert>
						</TagMobileToast>
					</>
				) : null}
			</div>

		</>
	);
}
