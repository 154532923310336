import { User } from "oidc-client";
import React, { Component } from "react";
import { connect } from "react-redux";

function DebugPage(props: any) {
  return (
    <div>
      {console.log(props)}
      {props.user ? (
        <div>
          <h3> Welcome, {props.user.profile.given_name}!</h3>

          <table className="table">
            <tbody>
              <tr>
                <td>Identity token</td>
                <td>{props.user.id_token}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>Please login</div>
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps)(DebugPage);
