import React, { useEffect, useState } from 'react'
import backarrow from '../../images/angle_left.svg'
import spreadsheet from '../../images/spreadsheet.svg'
import filters from '../../images/iconFilter.svg'
import iconFilterWhite from "../../images/iconFilterWhite.svg";
import completed from '../../images/completed.svg'
import progress from '../../images/progress.svg'
import schedule from '../../images/schedule.svg'
import show from '../../images/show.svg'

import '../Timesheet/TimesheetFilter/TimesheetFilter.scss';
import PageFooter from '../PageFooter/PageFooter'
import { motion } from 'framer-motion'
import { containerVariants, getTotalTimeTrackerCount, getTotalTimeTrackerStatusCount } from '../../types/constant'
import { getTimeTrackers } from '../../api/api'
import TimeTrackerSkeleton from './TimeTrackerSkeleton'
import TimetrackerFilter from './TimetrackerFilter/TimetrackerFilter'
import { useDispatch, useSelector } from 'react-redux'
import { allTimeTrackerDetails, setTimeTrackerStatusDetails } from './TimeTrackerSlice'
import { useNavigate } from 'react-router-dom'
import { setPlacementDetailName } from '../PlacementDetails/PlacementDetailsSlice'
export default function TimeTracker() {
	const [_loading, setLoading] = React.useState(true)
	const [_httpStatusCode, setHttpStatusCode] = React.useState(0)
	const [getTrackerTime, setTrackerTime] = useState<any[]>([])
	const [openTimetrackerFilterSlider, setOpenTimetrackerFilterSlider] = useState(false);
	const [_showSkeleton, setShowSkeleton] = useState(true);
	const [filteredResults, setFilteredResults] = useState<any[]>([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { timeTrackerFilterList } = useSelector(allTimeTrackerDetails);
	
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSkeleton(false)
		}, 5000)
		return () => clearTimeout(timer)
	}, [])
	useEffect(() => {
		getTrackerTimeApi()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function getTrackerTimeApi() {
		try {
			const res = await getTimeTrackers()
			if (Object.keys(res?.data).length && res?.status === 200) {
				// received api data successfully

				if (res.data) {
					setLoading(false)
				}
				setTrackerTime(res.data)
				setHttpStatusCode(res.status)
			} else {
				setLoading(false)
				setHttpStatusCode(res!.status)
			}
		} catch (err) {
			console.log('err:', err)
			return []
		}
	}

	useEffect(() => {
		if(timeTrackerFilterList && timeTrackerFilterList.length > 0) {
			const output = getTrackerTime.map((element) => {
				return {...element,
					placements: element.usersinfo.filter((value: any) =>
					timeTrackerFilterList.includes(value.category)).length,
					usersinfo: element.usersinfo.filter((value: any) =>
					timeTrackerFilterList.includes(value.category)),
			  	};
			});
			setFilteredResults(output);
			
		} else{
			setFilteredResults(getTrackerTime);
		}
		
	},[timeTrackerFilterList, getTrackerTime])

	const redirectToDetailsPage = (status: any) =>{
		dispatch(setTimeTrackerStatusDetails(status));
		navigate("/time-tracker-status-details");
	}

	const redirectToPlacementDetails = (valueName: any,valueStatus: any,valueShifts: any,valueLocation: any,valueCheckin:any,valueChekout:any) => {
		const payload = {
			placementDetailName: valueName,
			placementDetailStatus: valueStatus,
			placementDetailShifts: valueShifts,
			placementDetailLocation: valueLocation,
			placementDetailCheckin: valueCheckin,
			placementDetailCheckout: valueChekout
			
		};
		dispatch(setPlacementDetailName(payload));
		navigate("/placement-details");
	};
	

	return (
		<motion.div
			className="motionDiv"
			initial="hidden"
			animate="visible"
			exit="exit"
			variants={containerVariants}
		>
			{_loading ? (
				<TimeTrackerSkeleton />
			) : (
				<>
					<div className="time_tracker_header">
						<div className="time_tracker_header_title">Time Tracker</div>
						<div className="time_tracker_header_icons">
							<img
								className="time_tracker_header_icons_backarrow"
								src={backarrow}
								alt="backarrow"
							></img>
							<img
								className="time_tracker_header_icons_rightarrow"
								src={backarrow}
								alt="backarrow"
							></img>
							<img
								className="time_tracker_header_icons_spreadsheet"
								src={spreadsheet}
								alt="spreadsheet"
								onClick={() => navigate("/export-attendance-report")}
							></img>
						</div>
					</div>
					<div className="time_tracker_filter">
						<div className="time_tracker_filter_time">
							<div className="time_tracker_filter_time_title">
								Tue, 15th March 2022
							</div>
							<div className="time_tracker_filter_time_shifts">
								<div className="time_tracker_filter_time_shifts_shift">
									{' '}
									<b>{getTrackerTime.length}</b> Shifts
								</div>
								<div className="time_tracker_filter_time_shifts_shift">
									<b>{getTotalTimeTrackerCount(getTrackerTime)}</b> Placements
								</div>
							</div>
						</div>
						<div 
							className={`time_tracker_filter_time_filters ${(timeTrackerFilterList && timeTrackerFilterList.length > 0) 
								? "selectedFilterButton" 
								: ""}`} 
								onClick={() => setOpenTimetrackerFilterSlider(true)}>
							<img
								className="time_tracker_filter_time_filters_img"
								src={timeTrackerFilterList && timeTrackerFilterList.length > 0 
									? iconFilterWhite 
									: filters }
								alt=""
							></img>
							Filter
						</div>
					</div>
					<section className="timetracker-main-card">
						<div className="time_tracker_infos">
							<div className="time_tracker_infos_box" onClick={() => redirectToDetailsPage("Completed")}>
								<div className="time_tracker_infos_box_text">
									Completed
									<p>
										<b>{getTotalTimeTrackerStatusCount("Completed", getTrackerTime,true)}</b>
									</p>
								</div>
								<div className="time_tracker_infos_box_icon">
									<img src={completed} alt="completed" />
								</div>
							</div>

							<div className="time_tracker_infos_box" onClick={() => redirectToDetailsPage("In Progress")}>
								<div className="time_tracker_infos_box_text">
									In Progress
									<p>
									<b>{getTotalTimeTrackerStatusCount("In Progress", getTrackerTime,true)}</b>
									</p>
								</div>
								<div className="time_tracker_infos_box_icon time_tracker_infos_box_icon_progress">
									<img src={progress} alt="progress" />
								</div>
							</div>

							<div className="time_tracker_infos_box" onClick={() => redirectToDetailsPage("Scheduled")}>
								<div className="time_tracker_infos_box_text">
									Scheduled
									<p>
									<b>{getTotalTimeTrackerStatusCount("Scheduled", getTrackerTime,true)}</b>
									</p>
								</div>
								<div className="time_tracker_infos_box_icon time_tracker_infos_box_icon_schedule">
									<img src={schedule} alt="scheduled" />
								</div>
							</div>

							<div className="time_tracker_infos_box" onClick={() => redirectToDetailsPage("No Show")}>
								<div className="time_tracker_infos_box_text">
									No show
									<p>
									<b>{getTotalTimeTrackerStatusCount("No Show", getTrackerTime,true)}</b>
									</p>
								</div>
								<div className="time_tracker_infos_box_icon time_tracker_infos_box_icon_noshow">
									<img src={show} alt="No show" />
								</div>
							</div>
						</div>

						{filteredResults.map((dataIn) => (
							<div
								className="timetracker-main-card-content-pageTitle"
								key={dataIn.shiftId}
							>
								<div className="timetracker-main-card-shifts">
									<div className="timetracker-main-card-shifts-time">
										<b>{dataIn.shift}</b>
										<span className="timetracker-main-card-shifts-count">
										{dataIn.placements === 0 || dataIn.placements > 1
											? `${dataIn.placements} Placements`
											: `${dataIn.placements} Placement`}
										</span>
									</div>

									{dataIn.usersinfo.map((dataIns: any) => (
										<div
											className="timetracker-main-card-containerBox"
											key={dataIns.id}
										>

											<div className="timetracker-main-cards" >
												<div className="timetracker-main-card-body" onClick={() => redirectToPlacementDetails(dataIns.name,dataIns.status,dataIn.shift,dataIns.category,dataIns.checkin,dataIns.checkout)}>
													<div className="timetracker-main-card-name">
														{dataIns.name}
														<span
															className={
																dataIns.status === 'Completed'
																	? 'timetracker-main-card-completed'
																	: dataIns.status === 'In Progress'
																		? 'timetracker-main-card-inprogress'
																		: dataIns.status === 'No Show'
																			? 'timetracker-main-card-noshow'
																			: 'timetracker-main-card-scheduled'
															}
														>
															{dataIns.status}
														</span>
													</div>
													<div className="timetracker-main-card-location">
														{dataIns.category}
													</div>
													<div className="timetracker-main-card-names">
														<h5>
															Check in: {dataIns.checkin} - Check out: {''}
															{dataIns.checkout}
														</h5>
														<span className="timetracker-main-card-label">
															{dataIns.label}
														</span>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</section>
					<PageFooter />
				</>
			)}
			{openTimetrackerFilterSlider && (
				<TimetrackerFilter
					onDismiss={() => setOpenTimetrackerFilterSlider(false)}
				/>
			)}
		</motion.div>
	)
}
