import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { containerVariants, ExportErrorInfo, ExportInfo } from "../../types/constant";
import iconCancel from "../../images/iconCancel.svg";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../images/angle_left.svg";
import {
	TagButton,
	TagInput,
	TagMobileAlert,
	TagMobileDateTimeInput,
	TagMobileToast,
	TagSwitch,
	TagTextarea,
} from "@tag/tag-components-react-v3";
import "./ExportAttendanceReport.scss";
import { useForm } from "react-hook-form";
import { selectExportInfo, setEmailError, setEndDate, setEndDateError, setExportErrors, setStartDate, setStartDateError } from "./ExportAttendanceSlice";
import { useDispatch, useSelector } from "react-redux";



export const ExportAttendanceReport = () => {
	const navigate = useNavigate();
	const [showToast, setShowToast] = React.useState(false);
	const [inputEmail, setInputEmail] = useState<any>("");
	const [inputMessage, setInputMessage] = useState<any>("");
	const [inputPushNotification, setInputPushNotification] = useState<any>(true);
	
	const dispatch = useDispatch();
	//input control ref
    const startDate = React.useRef<any>(null);
    const endDate = React.useRef<any>(null);
    const email = React.useRef<any>(null);
    const message = React.useRef<any>(null);
    const pushNotify = React.useRef<any>(null);

	const theAddExportInfo = {} as ExportInfo;
	const formSubmit = () => {
		theAddExportInfo.StartDate = startDate.current.value ?? "";
		theAddExportInfo.EndDate = endDate.current.value ?? "";
		theAddExportInfo.EmailId = email.current.value ?? "";
		theAddExportInfo.Message = message.current.value ?? "";
		theAddExportInfo.PushNotify = inputPushNotification ?? "";
		

		let isStartDateValid = false;
		let isEmailValid = false;
		let isEndDateValid = false;

		if(exportData.StartDate === undefined){ 
			dispatch(setStartDateError("Please enter start date")) 
		} else{
			isStartDateValid = true;
			dispatch(setStartDateError("")) 
		}

		if(exportData.EndDate === undefined){
			isEndDateValid = true;
		} else if(exportData.EndDate < exportData.StartDate) {
			dispatch(setEndDateError("End date must be greater than start date")) 
		} else{
			isEndDateValid = true;
			dispatch(setEndDateError("")) 
		}

		if(inputEmail?.length === 0){ 
			dispatch(setEmailError("Please enter an email address")) 
		} else{
			dispatch(setEmailError("")) 
			const pattern = /^(?!.*?\.\.)[^\W_](?:[\w.#-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/gm
			const result = pattern.test(email.current.value);
			if(!result){
				dispatch(setEmailError("Please enter valid email address")) 
			} else {
				isEmailValid = true;
				dispatch(setEmailError("")) 
			}
		}

		if(isEmailValid && isStartDateValid && isEndDateValid){
			console.log("data:", theAddExportInfo);
			reset();
			navigate("/time-tracker");
		}
	}

	function reset() {
        dispatch(setStartDate(undefined));
		dispatch(setEndDate(undefined));
		dispatch(setEmailError(""));
		dispatch(setEndDateError("")); 
		dispatch(setStartDateError("")); 
		theAddExportInfo.EmailId = email.current.value ?? "";
		theAddExportInfo.Message = message.current.value ?? "";
		theAddExportInfo.PushNotify = inputPushNotification ?? "";
    }

	//FETCH REDUX VALUES OF ERROR MESSAGES
	const { ExportReportError, ExportReportData } = useSelector(selectExportInfo);
	const errors = ExportReportError as ExportErrorInfo

	const exportData = ExportReportData as ExportInfo;

	const { handleSubmit } = useForm();

	
	return (
		<>
			<motion.div
				className="motionDiv"
				initial="hidden"
				animate="visible"
				exit="exit"
				variants={containerVariants}
			>
				<div className="export-attendance-report_header">
					<div className="export-attendance-report_header-back">
						<img
							src={arrowBack}
							className="export-attendance-report_header-back-size"
							alt="Arrow Back"
							onClick={() => { navigate("/time-tracker"); reset() }}
						/>
					</div>
					<div className="export-attendance-report_title">
						Export Attendance Report
					</div>
					<div className="export-attendance-report_action">
						<img
							src={iconCancel}
							alt="Cancel icon"
							className="export-attendance-report_action-size"
							onClick={() => { navigate("/time-tracker"); reset() }}
						/>
					</div>
				</div>

				<div className="export-attendance-report_form" id="startDate">
					<form onSubmit={handleSubmit(formSubmit)} className="export-attendance-report_form-wrapper">
						<div className="export-attendance-report_form-content">
							<label className="export-attendance-report_form-label">
								Enter Start Date
							</label>
							<div className="export-attendance-report_form-inputControl">
								<div className={`export-attendance-report_form-input ${errors.StartDate ? "showErrorMessage" : ""}`}>
									<TagMobileDateTimeInput 
										type="date" 
										placeholder='00/00/0000'
										formattingOptions='DD/MM/YYYY'
										name="startDate"
										ref={startDate}
										value={exportData.StartDate}
										className="date-box"
										onChange={() => dispatch(setStartDate(startDate.current.value))}
									>
									</TagMobileDateTimeInput>
								</div>
								{errors.StartDate && 
									<div className="errorMessage" role="alert">
										{errors.StartDate}
									</div>
								}
							</div>

							<label className="export-attendance-report_form-label">
								Enter End Date
								<span className="export-attendance-report_form-label-span">(Optional)</span>
							</label>
							<div className="export-attendance-report_form-inputControl">
							<div className={`export-attendance-report_form-input ${errors.EndDate ? "showErrorMessage" : ""}`}>
									<TagMobileDateTimeInput 
										type="date" 
										placeholder='00/00/0000'
										name="endDate"
										ref={endDate}
										className="date-box"
										value={exportData.EndDate}
										onChange={() => dispatch(setEndDate(endDate.current.value))}
										formattingOptions='DD/MM/YYYY'
									>
									</TagMobileDateTimeInput>
								</div>
								{errors.EndDate && 
									<div className="errorMessage" role="alert">
										{errors.EndDate}
									</div>
								}
							</div>

							<label className="export-attendance-report_form-label">
								Recipient Email Address
							</label>
							<div className="export-attendance-report_form-inputControl">
								<div className="export-attendance-report_form-input">
									<div className={`export-attendance-report_form-inputBox ${errors.EmailId ? "showErrorMessage" : ""}`}>
										<TagInput
											name='emailId' 
											placeholder='example@email.com' 
											onChange={() => setInputEmail(email.current.value)}
											ref={email}
											className="input-box"
											autocomplete="off"
                                            value={inputEmail}
                                        />
									</div>
									{errors.EmailId && (
										<div className="errorMessage" role="alert">
											{errors.EmailId}
										</div>
									)}
								</div>

							</div>

							<label className="export-attendance-report_form-label">
								Message
								<span className="export-attendance-report_form-label-span">(Optional)</span>
							</label>
							<div className="export-attendance-report_form-inputControl">
								<div className="export-attendance-report_form-input">
								<TagTextarea 
									className=""
									textareaId='textAreaId'
									name='message'
									onChange={() => setInputMessage(message.current.value)}
									ref={message}
									placeholder='Enter a message here' />
								</div>
							</div>


							<label className="export-attendance-report_form-label">
								Send a copy to my email
							</label>
							<div className="export-attendance-report_form-input">
								<TagSwitch
									className="Push"
									size="normal"
									labelPlacement="right"
									checked
									accent="navy"
									name="pushNotify"
									ref={pushNotify}
									defaultValue={inputPushNotification}
									onSwitchValueChange={(e) => setInputPushNotification(e.detail.checked)}
								></TagSwitch>
							</div>

						</div>
						<div className="export-attendance-report_form-button full-width__button">
							<TagButton
								type="submit"
								device="mobile"
								text="Send Report"
								accent="navy"
								className="customButton"
								role="button"
							></TagButton>
						</div>
					</form>
				</div>
			</motion.div>

			<div className={showToast ? "z-indexed" : ""}>
				{showToast ? (
					<>
						<TagMobileToast position='top'>
							<TagMobileAlert
								size="fullWidth"
								showDuration={1500}
								closeable
								closeTransition='slideUp'
							>
								<div className="div_alert">
									<span className="span_alert">
										Your data sent successfully.
									</span>
								</div>
							</TagMobileAlert>
						</TagMobileToast>
					</>
				) : null}
			</div>

		</>
	);
};

