export const PageVariantsNone = {
	initial: {
		opacity: 0
	},
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	},
}

export const PageTransitionNone = {
	duration: "0",
	type: "ease"
}

export const PageVariantsLeft = {
	initial: {
		opacity: 0,
		x: "-25vw"
	},
	in: {
		opacity: 1,
		x: 0,
		zIndex: 1
	},
	out: {
		opacity: 0.5,
		x: "-25vw"
	},
}
export const PageVariantsPushInFromRight = {
	initial: {
		x: "100vw"
	},
	in: {
		x: 0,
		zIndex: 20
	},
	out: {
		x: "100vw"
	},
	outTop: {
		y: "-100vh"
	},
}
export const PageVariantsPushOutToLeft = {
	initial: {
		x: 0
	},
	in: {
		x: 0,
		zIndex: 1
	},
	out: {
		x: "-100vw"
	},
}
export const PageVariantsPushInFromLeft = {
	initial: {
		x: "-100vw"
	},
	in: {
		x: 0,
		zIndex: 1
	},
	out: {
		x: "-100vw"
	},
}

export const PageSlideOutLet = {
	initial: {
		opacity: 1,
		x: 0
	},
	out: {
		opacity: 0.5,
		x: "-100vw"
	},
}

export const PagePushTransition = {
	duration: "0.3",
	type: "ease"
}

export const PageVariantsSlideOutTop = {
	initial: {
		y: 0
	},
	in: {
		y: 0
	},
	out: {
		y: "100vw"
	},
}

export const pageVariants = {
	initial: {
		opacity: 0,
		x: "-100vw",
		scale: 0.8
	},
	in: {
		opacity: 1,
		x: 0,
		scale: 1
	},
	out: {
		opacity: 0,
		x: "100vw",
		scale: 1.2
	}
};

export const pageTransition = {
	type: "tween",
	ease: "easeInOut",
	duration: 0.2,
	delay:0
};

export const containerVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: pageTransition
	},
	exit: {
		opacity: 1,
		transition: pageTransition
	}
}

//GET COUNT OF EACH STATUS
export const getTotalTimeTrackerStatusCount = (status: any, getTrackerTime: any, isHomePage: boolean) => {
	const list: any[] = [];
	getTrackerTime?.forEach((dataIn: any) => {
		dataIn.usersinfo?.forEach((value: any) => {
			if (value.status ===  status) {
				list.push(value.id);
			}
		});
	});
	if(isHomePage){
		return list.length
	} else{
		if(list.length === 0){
			return `0 Placements`;	
		} else if(list.length === 1){
			return `1 Placement`;
		} else if(list.length > 1) {
			return `${list.length} Placements`;
		}
	}
	
}

export const getTotalTimeTrackerCount = (getTrackerTime: any) => {
	const list: any[] = [];
	getTrackerTime?.forEach((dataIn: any) => {
		dataIn.usersinfo?.forEach((value: any) => {
			list.push(value.id);
		});
	});
	return list.length;
}


export type ExportInfo = {
	StartDate: Date;
	EndDate: Date;
	EmailId: string;
	Message: string;
	PushNotify: boolean;
};

export type ExportErrorInfo = {
	StartDate?: string;
	EndDate?: string;
	EmailId?: string;
	Message?: string;
	PushNotify?: string;
};

export type ExportAttendanceInitialState = {
	ExportReportError: ExportErrorInfo;
	ExportReportData: ExportInfo;
}

export type MyAccountInfo = {
	title: string;
	firstName: string;
	middleName: string;
	lastName: string;
	mobileNumber: number;
	pushNotify: boolean;
};

export type MyAccountErrorInfo = {
	Title?: string;
	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	PhoneNumber?: number;
	PushNotify?: boolean;
};

export type MyAccountInitialState = {
	MyAccountError: MyAccountErrorInfo;
	MyAccountData: MyAccountInfo;
}