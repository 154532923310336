import React from "react";
import { connect } from "react-redux";
import { CallbackComponent, redirectSuccess } from "redux-oidc";
import { push } from "react-router-redux";
import userManager from "../../utils/userManager";
import { useNavigate } from "react-router-dom";

function CallbackPage(props: any) {
  const navigate = useNavigate();

  return (
    <>
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          props.dispatch(push("/"));
          navigate("/");
        }}
        errorCallback={(error) => {
          props.dispatch(push("/"));
          console.error(error);
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    </>
  );
}

export default connect()(CallbackPage);
