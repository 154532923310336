import "./Home.scss";
import { TagSkeleton } from '@tag/tag-components-react-v3';
export default function HomeSkeleton() {
    return (
        <>
            <div className="App-page">
                <div className="homePage">
                    <div className="homePage-img">
                        <TagSkeleton
                            width='240px'
                            height='200px'>
                        </TagSkeleton>
                    </div>
                    <div className="homePage-card">
                        <div className="homePage-card_viewtimesheet">
                            <div className="homePage-card_viewtimesheet_viewtimesheets">
                                <TagSkeleton
                                    width="32px"
                                    height="32px"

                                ></TagSkeleton>
                                <div className="homePage-card_viewtimesheet_viewtimesheets_title">
                                    <TagSkeleton
                                        width="100px"
                                        height="15px"

                                    ></TagSkeleton>
                                </div>
                            </div>
                            <div className="homePage-card__righticon">
                                <TagSkeleton
                                    width="20px"
                                    height="20px"
                                ></TagSkeleton>
                            </div>
                        </div>
                        <div className="homePage-card_viewtimesheet">
                            <div className="homePage-card_viewtimesheet_viewtimesheets">
                                <TagSkeleton
                                    width="32px"
                                    height="32px"
                                ></TagSkeleton>
                                <div className="homePage-card_viewtimesheet_viewtimesheets_title">
                                    <TagSkeleton
                                        width="120px"
                                        height="15px"
                                    ></TagSkeleton>
                                </div>
                            </div>
                            <div className="homePage-card__righticon">
                                <TagSkeleton
                                    width="20px"
                                    height="20px"
                                ></TagSkeleton>
                            </div>
                        </div>

                        <div className="homePage-card_viewtimesheet">
                            <div className="homePage-card_viewtimesheet_viewtimesheets">
                                <TagSkeleton
                                    width="32px"
                                    height="32px"
                                ></TagSkeleton>
                                <div className="homePage-card_viewtimesheet_viewtimesheets_title">
                                    <TagSkeleton
                                        width="150px"
                                        height="15px"
                                    ></TagSkeleton>
                                </div>
                            </div>
                            <div className="homePage-card__righticon">
                                <TagSkeleton
                                    width="20px"
                                    height="20px"
                                ></TagSkeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
