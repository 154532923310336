import { createSlice } from "@reduxjs/toolkit";

const radioFilterInitialState = {
    radioAscFilter: "",
    radioValueFilter: "",
}

const radioFilterSlice = createSlice({
    name: "radioFilters",
    initialState: radioFilterInitialState,
    reducers: {
        setRadioList: (state, {payload}) => {
            state.radioAscFilter = payload.radioAscFilter;
            state.radioValueFilter = payload.radioValueFilter;
        }
    }
})

export const {setRadioList} = radioFilterSlice.actions;
export const getAllRadioValue = (state: any) => state.radioFilters;
export default radioFilterSlice.reducer;