import React from 'react'
import './TimesheetHistory.scss'
import PageFooter from '../PageFooter/PageFooter'
import { TagSkeleton } from '@tag/tag-components-react-v3'

export default function TimesheetHistorySkeleton() {
	
	return (
		<>
			<div className="timeDetails_header">
				<div className="timeDetails_header_back">
					<TagSkeleton width="20px" height="20px"></TagSkeleton>
				</div>
				<div className="timeDetails_header_title">
					<TagSkeleton width="150px" height="20px"></TagSkeleton>
				</div>
			</div>
			<div className="App-page">
				<div className="history_card">
					<div className="history_card_body">
						<div className="history_card_body_head">
							<TagSkeleton width="136px" height="18px"></TagSkeleton>{' '}
							<span>
								<TagSkeleton width="27px" height="12px"></TagSkeleton>
							</span>
						</div>
						<p className="history_card_body_name">
							<TagSkeleton width="90px" height="12px"></TagSkeleton>
						</p>
					</div>

					<div className="history_card_body">
						<div className="history_card_body_head">
							<TagSkeleton width="136px" height="18px"></TagSkeleton>
							<span>
								<TagSkeleton width="27px" height="12px"></TagSkeleton>
							</span>
						</div>
						<p className="history_card_body_name">
							<TagSkeleton width="90px" height="12px"></TagSkeleton>
						</p>
					</div>

					<div className="history_card_body">
						<div className="history_card_body_head">
							<TagSkeleton width="136px" height="18px"></TagSkeleton>
							<span>
								<TagSkeleton width="27px" height="12px"></TagSkeleton>
							</span>
						</div>
						<p className="history_card_body_name">
							<TagSkeleton width="90px" height="12px"></TagSkeleton>
						</p>
						<p className="history_card_body_message">
							<TagSkeleton width="90px" height="16px"></TagSkeleton>
						</p>
					</div>
					<div className="history_card_body">
						<div className="history_card_body_head">
							<TagSkeleton width="136px" height="18px"></TagSkeleton>{' '}
							<span>
								<TagSkeleton width="27px" height="12px"></TagSkeleton>
							</span>
						</div>
						<p className="history_card_body_name">
							<TagSkeleton width="90px" height="12px"></TagSkeleton>
						</p>
					</div>
				</div>
			</div>
			<PageFooter />
		</>
	)
}
