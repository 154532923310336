import "./PageFooter.scss";
import { Link, useLocation  } from 'react-router-dom';
import iconHome from "../../images/iconHome.svg";
import iconHomeActive from "../../images/iconHomeActive.svg";
import iconTimecard from "../../images/iconTimecard.svg";
import iconTimecardActive from "../../images/iconTimecardActive.svg";
import iconsNotification from "../../images/iconsNotification.svg";
import iconsNotificationActive from "../../images/iconsNotificationActive.svg";
import iconTimeTrackerActive  from "../../images/iconTimeTrackerActive.svg";
import iconTimeTracker  from "../../images/iconTimeTracker.svg";

export default function PageFooter() {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <>
            <div className="pageFooter">
                <Link to="/home" className={splitLocation[1] === "home" ? "pageFooter-active" : ""}>
                    <div className="pageFooter-nav">
                        <div className="pageFooter-icon">
                            <img src={splitLocation[1] === "home" ? iconHomeActive : iconHome} alt="Home icon"  className="pageFooter-icon__size"  />
                        </div>
                        <div className="pageFooter-text">Home</div>
                    </div>
                </Link>
                <Link to="/timesheet" className={(splitLocation[1] === "timesheet" || splitLocation[1] === "timesheet-history") ? "pageFooter-active" : ""}>
                    <div className="pageFooter-nav">
                        <div className="pageFooter-icon">
                            <img src={(splitLocation[1] === "timesheet" || splitLocation[1] === "timesheet-history") ? iconTimecardActive : iconTimecard } alt="Add icon"  className="pageFooter-icon__size"  />
                        </div>
                        <div className="pageFooter-text">Timesheets</div>
                    </div>
                </Link>
                <Link to="/time-tracker" className={(splitLocation[1] === "time-tracker" || splitLocation[1] === "placement-details" ) ? "pageFooter-active" : ""}>
                    <div className="pageFooter-nav">
                        <div className="pageFooter-icon">
                            <img src={(splitLocation[1] === "time-tracker" || splitLocation[1] === "placement-details") ? iconTimeTrackerActive : iconTimeTracker } alt="Time Tracker icon"  className="pageFooter-icon__size"  />
                        </div>
                        <div className="pageFooter-text">Tracker</div>
                    </div>
                </Link>
                <Link to="/notifications" className={(splitLocation[1] === "notifications") ? "pageFooter-active" : ""}>
                    <div className="pageFooter-nav">
                        <div className="pageFooter-icon">
                            <img src={(splitLocation[1] === "notifications") ? iconsNotificationActive : iconsNotification } alt="Add icon"  className="pageFooter-icon__size"  />
                            <div className="notificationDot"></div>
                        </div>
                        <div className="pageFooter-text">Notifications</div>
                    </div>
                </Link>
            </div>
        </>
    )
}
