import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { store } from "./App/store";
import { BrowserRouter } from "react-router-dom";
import { OidcProvider } from "redux-oidc";
import userManager from "./utils/userManager";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <OidcProvider store={store} userManager={userManager}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </OidcProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
