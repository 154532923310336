import { createSlice } from "@reduxjs/toolkit";

const SelectAppInitialState = {
    radioSelectLocation: ""
}

const SelectedAppSlice = createSlice({
    name: "selectappSlice",
    initialState: SelectAppInitialState,
    reducers: {
        setSelectAppName: (state, {payload}) => {
            state.radioSelectLocation = payload.radioSelectLocation;
        }
    }
})

export const {setSelectAppName} = SelectedAppSlice.actions;
export const getAllSelectApp = (state: any) => state.selectappSlice;
export default SelectedAppSlice.reducer;