import React from "react";
import { TagMobileSideMenu } from "@tag/tag-components-react-v3";
import iconBranding from "../../images/iconBranding.svg";
import iconMenu from "../../images/iconMenu.svg";
import iconClose from "../../images/iconMultiply.svg";
import iconUser from "../../images/iconUser.svg";
import iconSignOut from "../../images/iconSignOut.svg";
import "./PageHeader.scss";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import userManager from "../../utils/userManager";

function PageHeader(props: any) {
  const [openSideNav, setOpenSideNav] = React.useState(false);
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const onLogoutButtonClick = () => {
    userManager.signoutRedirect();
    userManager.removeUser();
  };

  return (
    <>
      {props.user ? (
        <>
          <div className="pageHeader">
            <div
              className="pageHeader-menuIcon"
              onClick={() => setOpenSideNav(true)}
            >
              <img src={iconMenu} alt="menu icon" />
            </div>
            <div className="pageHeader-title" aria-labelledby="greetingText">
              Hello <span>{props.user.profile.name}</span>
            </div>
            <div className="pageHeader-clientLogo">
              <img src={iconBranding} alt="Branding" />
            </div>
          </div>
          <TagMobileSideMenu
            open={openSideNav}
            position="left"
            className="pageHeaderNavigation"
            onClosed={() => setOpenSideNav(false)}
          >
            <div className="app_header__user-details">
              <div className="app_header__user-info">
                <img
                  src={iconClose}
                  alt="Profile img"
                  className="app_header__close"
                  onClick={() => setOpenSideNav(false)}
                />
                <div className="app_header__user-name">
                  {props.user.profile.name}
                </div>
                <div className="app_header__user-email">
                  {props.user.profile.email}
                </div>
              </div>
            </div>
            <div className="app_header__menuList-wrapper">
              <Link
                to="/my-account"
                className={
                  splitLocation[1] === "my-account" ? "pageFooter-active" : ""
                }
              >
                <div className="app_header__menuList">
                  <div className="app_header__menuList-icon">
                    <img src={iconUser} alt="user icon" />
                  </div>
                  <div className="app_header__menuList-text">My Account</div>
                </div>
              </Link>
              <div
                className="app_header__menuList"
                onClick={onLogoutButtonClick}
              >
                <div className="app_header__menuList-icon">
                  <img src={iconSignOut} alt="user icon" />
                </div>
                <div className="app_header__menuList-text signOutText">
                  Sign Out
                </div>
              </div>
            </div>
            <div className="app_header__branding">
              <img src={iconBranding} alt="Branding" />
            </div>
          </TagMobileSideMenu>{" "}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    user: state.oidc.user,
    isAuthenticated: state.oidc.user && !state.oidc.user.expired,
  };
}

export default connect(mapStateToProps)(PageHeader);
