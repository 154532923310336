import {createSlice} from "@reduxjs/toolkit";

import { MyAccountErrorInfo, MyAccountInfo, MyAccountInitialState } from "../../types/constant";

const myAccountInitialState : MyAccountInitialState = {
    MyAccountError: {} as MyAccountErrorInfo,
    MyAccountData: {} as MyAccountInfo
}

export const MyAccountSlice = createSlice({
    name: "MyAccount",
    initialState: myAccountInitialState,
    reducers: {
        setMyAccountErrors: (state ,action) => {
            state.MyAccountError = action.payload;
            
        },
        setTitle:(state,action) => {
            state.MyAccountData.title = action.payload;
        },
        setFirstName:(state,action) => {
            state.MyAccountData.firstName = action.payload;
        },
        setMiddleName:(state,action) => {
            state.MyAccountData.middleName = action.payload;
        },
        setLastName:(state,action) => {
            state.MyAccountData.lastName = action.payload;
        },
        setPhoneNumber:(state,action) => {
            state.MyAccountData.mobileNumber = action.payload;
        },

        setTitleError:(state,action) => {
            state.MyAccountError.Title = action.payload;
        },
        setFirstNameError:(state,action) => {
            state.MyAccountError.FirstName = action.payload;
        },
        setMiddleNameError:(state,action) => {
            state.MyAccountError.MiddleName = action.payload;
        },
        setLastNameError:(state,action) => {
            state.MyAccountError.LastName = action.payload;
        },
        setPhoneNumberError:(state,action) => {
            state.MyAccountError.PhoneNumber = action.payload;
        }
        
    }
})

export const {setMyAccountErrors, setTitle, setFirstName, setMiddleName, setLastName, setPhoneNumber, setTitleError, setFirstNameError, setMiddleNameError, setLastNameError, setPhoneNumberError} = MyAccountSlice.actions;
export const selectMyAccountInfo = (state: any) => state.MyAccount;
export default MyAccountSlice.reducer;