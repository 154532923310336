import React from "react";
import { Link } from "react-router-dom";
import { TagSkeleton } from "@tag/tag-components-react-v3";
export default function AmendTimesheetSkeleton() {
	return (
		<>
			<div className="amend_header">
				<div className="amend_header_back">

				</div>
				<div className="amend_header_title">
					<TagSkeleton width="150px" height="20px"></TagSkeleton>
				</div>
				<Link to="">
					<div className="amend_header_action">
						<TagSkeleton width="25px" height="25px"></TagSkeleton>
					</div>
				</Link>
				<Link to="">
					<div className="amend_header_action">
						<TagSkeleton width="25px" height="25px"></TagSkeleton>
					</div>
				</Link>
			</div>

			<div className="amend_content">
				<table className="amend_content_table">
					<thead>
						<tr>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
						</tr>
					</thead>
					<tbody>
						{Array(5)
							.fill(0)
							.map((key, index) => (
								<tr key={index}>
									<td data-label="Number">
										<div className="amend_content_table_date">
											<TagSkeleton width='30px' height='20px' itemStyle={{ marginBottom: "5px" }}></TagSkeleton>
										</div>
										<div className="amend_content_table_day">
											<TagSkeleton width='40px' height='15px'></TagSkeleton>
										</div>
									</td>
									<td data-label="Name">
										<div className="amend_content_table_starttime">
											<TagSkeleton width="50px" height="15px"></TagSkeleton>
										</div>
									</td>
									<td data-label="Phone">
										<div className="amend_content_table_endtime">
											<TagSkeleton width="50px" height="15px"></TagSkeleton>
										</div>
									</td>
									<td data-label="Number">
										<div className="amend_content_table_payrate">
											<TagSkeleton width='50px' height='15px' itemStyle={{ marginBottom: "5px" }}></TagSkeleton>
										</div>
									</td>
									<td data-label="Date">
										<div className="amend_content_table_total">
											<TagSkeleton width="50px" height="15px"></TagSkeleton>
										</div>
									</td>
									<td>
										<div
											className="amend_content_table_image"

										>
											<TagSkeleton width="15px" height="15px"></TagSkeleton>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>

			<div className="amend_footer">
				<div className="amend_footer_total">
					<div className="amend_footer_total_text">
						<TagSkeleton width="70px" height="15px"></TagSkeleton>
					</div>
					<div className="amend_footer_total_text">
						<TagSkeleton width="70px" height="15px"></TagSkeleton>
					</div>
				</div>
				<div className="amend_footer_btn_approve">
					<TagSkeleton width='100%' height='45px' className='customButton'></TagSkeleton>
				</div>
			</div>
		</>
	)
}
