import React from "react";
import { TagButton, TagCheckbox, TagMobileSliderPopover } from "@tag/tag-components-react-v3";
import { useDispatch, useSelector } from "react-redux";
import { setTimeTrackerFilterList } from "../TimeTrackerSlice";
import "../../Timesheet/TimesheetFilter/TimesheetFilter.scss";
import "./TimetrackerFilter.scss";
import { allTimeTrackerDetails } from "../TimeTrackerSlice";

export default function TimetrackerFilter(props: any) {
    const { checkboxArray, onDismiss } = props;
    const dispatch = useDispatch();
    const { timeTrackerFilterList } = useSelector(allTimeTrackerDetails);
    
    const [theAllowedActivityType, setTheAllowedActivityType] = React.useState<Array<string>>(timeTrackerFilterList);
    //get all selected checkbox values
    function checkActivityType(checkboxName?: string) {
        const theTempAllowedActivityType = Object.assign([], theAllowedActivityType);
        if (theTempAllowedActivityType.indexOf(checkboxName ?? " ") === -1) {
            theTempAllowedActivityType.push(checkboxName ?? " ");
        }
        else {
            theTempAllowedActivityType.splice(
                theTempAllowedActivityType.indexOf(checkboxName ?? " "),
                1
            );
        }
        setTheAllowedActivityType(theTempAllowedActivityType);
    }
    function clearFilter(){
        const theTempAllowedActivityType = Object.assign([], theAllowedActivityType);
        theTempAllowedActivityType.length = 0;
        setTheAllowedActivityType(theTempAllowedActivityType);
    }
    //on Apply button click store all values in redux
    const onSubmitCheckbox = () => {
        onDismiss();
        const payload: any = { timeTrackerFilterList: theAllowedActivityType }
        dispatch(setTimeTrackerFilterList(payload));
    }
    return (
        <>
            <TagMobileSliderPopover
                open={true}
                heading={"Filter by App"}
                initialHeight="450px"
                className="customPopover"
                onClosed={() => onDismiss()}>
                    <span className="clearFilterButton" onClick={() => clearFilter()}>Clear Filter</span>
                <div className="FilterModal">
                    <div className="FilterModal-content">
                            <div className="FilterModal-checkbox">
                                <TagCheckbox
                                    rounded
                                    id="Ocado - Manchester Warehouse"
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name="Ocado - Manchester Warehouse"
                                    label="Ocado - Manchester Warehouse"
                                    colourScheme="default"
                                    onCheckboxValueChange={() => checkActivityType(
                                        "Manchester Warehouse"
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        "Manchester Warehouse"
                                    )}
                                />
                            </div>
                            <div className="FilterModal-checkbox">
                                <TagCheckbox
                                    rounded
                                    id="Ocado - Birmingham Warehouse"
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name="Ocado - Birmingham Warehouse"
                                    label="Ocado - Birmingham Warehouse"
                                    colourScheme="default"
                                    onCheckboxValueChange={() => checkActivityType(
                                        "Birmingham Warehouse"
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        "Birmingham Warehouse"
                                    )}
                                />
                            </div>
                            <div className="FilterModal-checkbox">
                                <TagCheckbox
                                    rounded
                                    id="Ocado - Farnborough Warehouse"
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name="Ocado - Farnborough Warehouse"
                                    label="Ocado - Farnborough Warehouse"
                                    colourScheme="default"
                                    onCheckboxValueChange={() => checkActivityType(
                                        "Farnborough Warehouse"
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        "Farnborough Warehouse"
                                    )}
                                />
                            </div>
                            {/* <div className="FilterModal-checkbox">                                
                                <TagCheckbox
                                    rounded
                                    id="Sainsburys - Birmingham Warehouse"
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name="Sainsburys - Birmingham Warehouse"
                                    label="Sainsburys - Birmingham Warehouse"
                                    colourScheme="default"
                                    onCheckboxValueChange={() => checkActivityType(
                                        "Birmingham Warehouse"
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        "Birmingham Warehouse"
                                    )}
                                />
                            </div> */}
                            <div className="FilterModal-checkbox">
                                <TagCheckbox
                                    rounded
                                    id="Sainsburys - Farnborough Store"
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name="Sainsburys - Farnborough Store"
                                    label="Sainsburys - Farnborough Store"
                                    colourScheme="default"
                                    onCheckboxValueChange={() => checkActivityType(
                                        "Farnborough Store"
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        "Farnborough Store"
                                    )}
                                />
                            </div>
                    </div>
                    <div className="FilterModal-footer">
                        <div className="FilterModal-footer-btnGrid">
                            <div className="FilterModal-footer-btn" onClick={() => onDismiss()}>
                                <TagButton
                                    className="customTernaryButton"
                                    text="Cancel"
                                    size="default"
                                    device="mobile">
                                </TagButton>
                            </div>

                            <div className="FilterModal-footer-btn FilterModal-footer-btn__filled " onClick={() => onSubmitCheckbox()}>
                                <TagButton
                                    device="mobile"
                                    text="Apply filter"
                                    accent="navy"
                                    className="customButton"
                                ></TagButton>
                            </div>
                        </div>
                    </div>
                </div>
            </TagMobileSliderPopover>
        </>
    )
}
