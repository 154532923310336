import React from "react";
import "./EditTimesheet.scss";
import { TagSkeleton } from "@tag/tag-components-react-v3";
import AddTimeEntry from "../AddTimeEntry/AddTimeEntry";

export default function EditTimesheetSkeleton() {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <div className="timeSheet-header">
                <div className="timeSheet-header__back">

                </div>
                <div className="timeSheet-header__title">
                    <TagSkeleton
                        width='150px'
                        height='20px'>
                    </TagSkeleton>
                </div>
                <div className="timeSheet-header__action timeSheet-header__close">
                    <TagSkeleton
                        width='25px'
                        height='25px'>
                    </TagSkeleton>
                </div>
                <div className="timeSheet-header__action timeSheet-header__close">
                    <TagSkeleton
                        width='25px'
                        height='25px'>
                    </TagSkeleton>
                </div>
            </div>
            <div className="timeSheet-content" >
                <table className="timeSheet-content__table">
                    <thead>
                        <tr>
                            <th><TagSkeleton width='50px' height='15px'></TagSkeleton></th>
                            <th><TagSkeleton width='50px' height='15px'></TagSkeleton></th>
                            <th><TagSkeleton width='50px' height='15px'></TagSkeleton></th>
                            <th><TagSkeleton width='50px' height='15px'></TagSkeleton></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(5)
                            .fill(0)
                            .map((key, index) => (
                                <tr key={index}>
                                    <td data-label="Number">
                                        <div className="timeSheet-content__table-date"><TagSkeleton width='30px' height='20px' itemStyle={{ marginBottom: "5px" }}></TagSkeleton></div>
                                        <div className="timeSheet-content__table-day"><TagSkeleton width='40px' height='15px'></TagSkeleton></div>
                                    </td>
                                    <td data-label="Name"><TagSkeleton width='50px' height='15px'></TagSkeleton></td>
                                    <td data-label="Phone"><TagSkeleton width='50px' height='15px'></TagSkeleton></td>
                                    <td data-label="Date">
                                        <div className="timeSheet-content__table-total"><TagSkeleton width='50px' height='15px'></TagSkeleton></div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div className="timeSheet-footer">
                <div className="timeSheet-footer__total">
                    <div className="timeSheet-footer__total-text">
                        <TagSkeleton width='100px' height='20px'></TagSkeleton>
                    </div>
                    <div className="timeSheet-footer__total-text">
                        <TagSkeleton width='100px' height='20px'></TagSkeleton>
                    </div>
                </div>
                <div className="timeSheet-footer-btnGrid">
                    <div className="timeSheet-footer-btn">
                        <TagSkeleton width='100%' height='45px' className='customButton'></TagSkeleton>
                    </div>
                    <div className="timeSheet-footer-btn timeSheet-footer-btn__approve ">
                        <TagSkeleton width='100%' height='45px' className='customButton'></TagSkeleton>
                    </div>
                </div>
            </div>
            {open && <AddTimeEntry onDismiss={() => setOpen(false)} />}
        </>
    )
}
