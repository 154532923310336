import React, { useEffect, useState } from "react";
import { TagButton, TagIcon } from "@tag/tag-components-react-v3";
import { useNavigate, Navigate } from 'react-router-dom';
import clarityLog from "../../images/clarity-logo.png";
import "./GetStarted.scss";
import "animate.css"
import { useLocalStorage } from "../../utils/useLocalStorage";

export default function GetStarted() {
    const navigate = useNavigate();
    const [showNextSlide, setShowNextSlide] = useState("slide1");
    const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);
    const [skipSplashScreen, setSkipSplashScreen] = useLocalStorage('skipSplashScreen', false)
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSplashScreen(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const closeSplashScreen = () => {
        setSkipSplashScreen(true);
        navigate("/home");
    }

    if (skipSplashScreen === true)
        return <Navigate to='/home' />
        
    return (
        <div className="getStarted-wrapper">
            {showSplashScreen ?
                <div className="getStarted-splash">
                    <div className="getStarted-splash_logo">
                        <img src={clarityLog} alt="Clarity logo" />
                    </div>
                    <div className="getStarted-splash_text">
                        Powered by <span><TagIcon
                            icon='Access'
                            accent='access'
                            size='default' />Access</span>
                    </div>
                </div> :
                <>
                    <div className={`getStarted-wrapper__toggle ${showNextSlide === "slide1" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>

                        <div className="getStarted">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    The new way of getting timesheets signed off
                                </div>
                                <div className="getStarted-text__subtext">
                                    No more paper! Approve timesheets using the app and pay team members quicker.
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={() => setShowNextSlide("slide2")}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>

                    {/* SLIDE 2 */}

                    <div className={`getStarted-wrapper__toggle ${showNextSlide === "slide2" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>
                        <div className="getStarted animate__animated animate__fadeInRight">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    Approve timesheets
                                </div>
                                <div className="getStarted-text__subtext">
                                    View the date, time and status of timesheets and quickly approve them.
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={() => setShowNextSlide("slide3")}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>

                    {/* SLIDE 3 */}
                    <div className={`getStarted-wrapper__toggle ${showNextSlide === "slide3" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>
                        <div className="getStarted animate__animated animate__fadeInRight">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    Amend timesheets
                                </div>
                                <div className="getStarted-text__subtext">
                                    Iron out mistakes and errors by amending incorrectly submitted timesheets.
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={() => setShowNextSlide("slide4")}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>

                    {/* SLIDE 4 */}
                    <div className={`${showNextSlide === "slide4" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>
                        <div className="getStarted animate__animated animate__fadeInRight">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    Query timesheets
                                </div>
                                <div className="getStarted-text__subtext">
                                    Need to clarify a timesheet? Send a message and let the candidate confirm for you.
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={() => setShowNextSlide("slide5")}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>

                    {/* SLIDE 5 */}
                    <div className={`${showNextSlide === "slide5" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>
                        <div className="getStarted animate__animated animate__fadeInRight">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    View the timesheet history
                                </div>
                                <div className="getStarted-text__subtext">
                                    See who’s interacted with the timesheet and explore any queries too!
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={() => setShowNextSlide("slide6")}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>

                    {/* SLIDE 6 */}
                    <div className={`${showNextSlide === "slide6" ? "getStarted-wrapper__show" : "getStarted-wrapper__hide"}`}>
                        <div className="getStarted animate__animated animate__fadeInRight">
                            <div className="getStarted-img">
                                <div className="getStarted-img__inner"></div>
                            </div>
                            <div className="getStarted-text">
                                <div className="getStarted-text__header">
                                    Bulk approve & amend
                                </div>
                                <div className="getStarted-text__subtext">
                                    Sometimes candidates share schedules and you can easily adjust or approve them.
                                </div>
                            </div>
                            <div className="getStarted-button full-width__button" >
                                <TagButton
                                    device="mobile"
                                    text="Next"
                                    accent="navy"
                                    className='customButton'
                                    onButtonClick={closeSplashScreen}
                                ></TagButton>
                                <div className="getStarted-button__skip" onClick={closeSplashScreen}>Skip</div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
