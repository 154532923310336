import React, { useEffect, useRef, useState } from "react";
import "./Timesheet.scss";
import PageFooter from "../PageFooter/PageFooter";
import { useNavigate } from "react-router-dom";
import iconschecked_checkbox from "../../images/iconschecked_checkbox.svg";
import iconCancelWhite from "../../images/iconCancelWhite.svg";
import iconSort from "../../images/iconSort.svg";
import iconSortWhite from "../../images/iconSortWhite.svg";
import iconSearch from "../../images/iconSearch.svg";
import iconEdit from "../../images/iconEdit.svg";
import iconFilterWhite from "../../images/iconFilterWhite.svg";
import iconTimeSearch from "../../images/iconTimeSearch.svg";
import iconFilter from "../../images/iconFilter.svg";
import TimeSheetSortFilter from "./TimesheetSortFilter/TimesheetSortFilter";
import iconCheckCircle from "../../images/iconCheckCircle.svg";
import TimesheetSkeleton from "./TimesheetSkeleton";
import TimeSheetFilter from "./TimesheetFilter/TimesheetFilter";
import {
	TagButton,
	TagCheckbox,
	TagSearchbox,
} from "@tag/tag-components-react-v3";
import { useDispatch, useSelector } from "react-redux";
import { getAllCheckboxes, getNewOpenCheckboxes } from "./TimeSheetFilterSlice";
import { getAllRadioValue } from "./FilterRadioSlice";
import { motion } from "framer-motion";
import { containerVariants } from "../../types/constant";
import { setTimeSheetDetailName } from "../TimesheetDetail/TimesheetDetailSlice";
import { getAllTimesheets } from "../../api/api";

const Timesheet = () => {
	const [showSkeleton, setShowSkeleton] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSkeleton(false);
		}, 500);
		return () => clearTimeout(timer);
	}, []);
	const navigate = useNavigate();
	
	const [isChecked, setIsChecked] = useState(false);
	const [add, minus] = useState(true);
	const [style, setStyle] = useState("main-card-containerBox");
	const [isClicked, setIsClicked] = useState(false);
	const [show, setShow] = useState(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	
	const [filteredResults, setFilteredResults] = useState<any[]>([]);
	const [openFilterSlider, setOpenFilterSlider] = useState(false);
	const [openSortSlider, setOpenSortSlider] = useState(false);
	const [display, setIsDisplay] = useState(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [criteria, setCriteria] = useState("");
	const [_loading, setLoading] = React.useState(true);
	const { selectedCheckboxList } = useSelector(getAllCheckboxes);
	const { selectedCheckboxListForNewOpen } = useSelector(getNewOpenCheckboxes);
	const { radioAscFilter, radioValueFilter } = useSelector(getAllRadioValue);
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState<any>([]);
	const [_httpStatusCode, setHttpStatusCode] = React.useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		getTimeSheetApi();
		setData(data);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [data, setData] = useState<any[]>([]);
	async function getTimeSheetApi() {
        try {
            const res = await getAllTimesheets();
			if (Object.keys(res?.data).length && res?.status === 200) {
                // received api data successfully
                
                if (res.data) {
                    setLoading(false);
                }
                setData(res.data);
                setHttpStatusCode(res.status);
              }
            else{
                setLoading(false);
				setHttpStatusCode(res!.status);
                setData([]);
            }
            
        } catch (err) {
            console.log("err:", err);
            return [];
        }
		
    }
	useEffect(() => {
		//check if filter checkbox array has value and is not undefined
		if (selectedCheckboxList && selectedCheckboxList.length > 0) {
			const filteredArray = data?.filter((el) =>
				selectedCheckboxList?.includes(el.status)
			);
			setFilteredResults(filteredArray);
		} else {
			setFilteredResults(data);
		}
		//ID SINGLE CHECKBOX SELECTED COUNT IS EQUAL TO TOTAL DATA COUNT THEN ENABLE SELECT ALL BUTTON
		if(isCheck.length === data.length){
			setIsCheckAll(true);
		} else {
			setIsCheckAll(false);
		}
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
	}, [data, isCheck.length, selectedCheckboxList]);

	//FILTER FOR NEW & OPEN CHECKBOX AND FILTER BY STATUS CHECKBOXES
	useEffect(() => {
		//check if filter checkbox array has value and is not undefined
		if (
			selectedCheckboxListForNewOpen &&
			selectedCheckboxListForNewOpen?.length > 0
		) {
			for (let i = 0; i < selectedCheckboxListForNewOpen?.length; i++) {
				if (selectedCheckboxListForNewOpen[i] === "New") {
					const filteredArray = data.filter(
						(el) =>
							el.isVisited === "true" ||
							selectedCheckboxList?.includes(el.status)
					);
					setFilteredResults(filteredArray);
				} else if (selectedCheckboxListForNewOpen[i] === "Open") {
					const filteredArray = data.filter(
						(el) =>
							el.isVisited === "false" ||
							selectedCheckboxList?.includes(el.status)
					);
					setFilteredResults(filteredArray);
				} else {
					setFilteredResults(data);
				}
			}
		} else if (selectedCheckboxList && selectedCheckboxList?.length > 0) {
			let filteredArray = data.filter((el) =>
				selectedCheckboxList?.includes(el.status)
			);
			setFilteredResults(filteredArray);
		} else {
			setFilteredResults(data);
		}
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
	}, [data, selectedCheckboxList, selectedCheckboxListForNewOpen]);

	//FOR SORT FUNCTIONALITY
	useEffect(() => {
		//const sortData = filteredResults.length > 0 ? filteredResults : data;
		const tempArray = Object.assign([], filteredResults);
		if (radioAscFilter === "Ascending") {
			let sortedArray = [...tempArray].sort((a: any, b: any) => {
				if (a[radioValueFilter] > b[radioValueFilter]) {
					return 1;
				}
				if (b[radioValueFilter] > a[radioValueFilter]) {
					return -1;
				}
				return 0;
			});
			setFilteredResults(sortedArray);
		} else if (radioAscFilter === "Descending") {
			let sortedArray = [...tempArray].sort((a: any, b: any) => {
				if (a[radioValueFilter] > b[radioValueFilter]) {
					return -1;
				}
				if (b[radioValueFilter] > a[radioValueFilter]) {
					return 1;
				}
				return 0;
			});
			setFilteredResults(sortedArray);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [radioAscFilter, radioValueFilter]);

	useEffect(() => {
		if (
			selectedCheckboxList &&
			selectedCheckboxList.length > 0 &&
			criteria !== ""
		) {
			let filteredArray = data.filter((el) =>
				selectedCheckboxList?.includes(el.status)
			);
			filteredArray = filteredArray.filter((item) => {
				return item.name.toLowerCase().includes(criteria.toLowerCase());
			});
			setFilteredResults(filteredArray);
		} else if (selectedCheckboxList && selectedCheckboxList.length > 0) {
			let filteredArray = data.filter((el) =>
				selectedCheckboxList?.includes(el.status)
			);
			setFilteredResults(filteredArray);
		} else if (criteria !== "") {
			const tempFilteredData = data.filter((item) => {
				return item.name.toLowerCase().includes(criteria.toLowerCase());
			});
			setFilteredResults(tempFilteredData);
		} else {
			setFilteredResults(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteria, data]);

	function buttonToggle() {
		setIsChecked(!isChecked);
		setIsDisplay(false);
		minus(!add);
		changeStyle();
	}

	function searchToggle() {
		setIsClicked(!isClicked);
		setShow(!show);
		setCriteria("");
	}

	function selectToggle() {
		setIsChecked(false);
		setIsDisplay(true);
		minus(!add);
		setStyle("main-card-containerBox");
	}

	
	//FUNCTION FOR SELECT ALL CHECKBOX
	const handleSelectAll = (_e: any) => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(data.map((item: any) => item.id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	//FUNCTION FOR SINGLE CHECKBOX
	const handleClick = (e: any) => {
		const { id, checked } = e.target;
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item: any) => item !== id));
		}
		
	};

	const changeStyle = () => {
		setStyle("main-card-containersBox");
	};

	//Fetch status from data
	const uniqueAllowedCategory: any[] = [];
	if (data) {
		const allStatus = data.map((dataItem) => dataItem.status) // get all status
			.filter((item, index, array) => array.indexOf(item) === index); // filter out duplicates

		const counts = allStatus.map((status) => ({
			type: status,
			count: data.filter((item) => item.status === status).length,
		}));
		uniqueAllowedCategory.push(counts);
	}

	const redirectToDetails = (valueName: any, valueStatus: any) => {
		const payload = {
			timeSheetDetailName: valueName,
			timeSheetDetailStatus: valueStatus,
		};
		dispatch(setTimeSheetDetailName(payload));
		navigate("/timesheet-details");
	};

	return (
		<motion.div
			className="motionDiv"
			initial="hidden"
			animate="visible"
			exit="exit"
			variants={containerVariants}
		>
			{showSkeleton ? (
				<TimesheetSkeleton />
			) : (
				<>
					<div className="navbar-main-pageHeader">
						{isClicked ? (
							<TagSearchbox
								className="navbar-main-pageHeader-search"
								device="mobile"
								placeholder="Search timesheets"
								onSearch={(e) => setCriteria(e.detail.criteria)}
							/>
						) : null}

						<div className="navbar-main-pageHeader-title">
							<b>{show ? "Timesheets" : ""}</b>
						</div>
						{show ? (
							<div
								className="navbar-main-pageHeader-profileImg"
								onClick={() => {
									searchToggle();
								}}
							>
								<img
									src={iconSearch}
									alt="Search icon"
									className="navbar-main-pageHeader-icon-size"
								/>
							</div>
						) : (
							<div
								className="navbar-main-pageHeader-profileImg"
								onClick={() => {
									searchToggle();
								}}
							>
								Cancel
							</div>
						)}
					</div>

					<div className="navbar-pageHeader">
						{isChecked ? (
							<TagCheckbox
								rounded
								id="0"
								name="SelectAll"
								className="navbar-pageHeader-card-checkbox"
								size="normal"
								accent="navy"
								colourScheme="default"
								onCheckboxValueChange={handleSelectAll}
								checked={isCheckAll}
							/>
						) : null}
						<div className="navbar-pageHeader-title">
							<b>
								{add ? (
									<span>
										<b>{filteredResults.length}</b>{" "}
										{filteredResults.length === 0 || filteredResults.length > 1
											? "Timesheets"
											: "Timesheet"}
										{" "}
									</span>
								) : (
									<span>Select all</span>
								)}
							</b>
						</div>

						<div
							className={`navbar-pageHeader-profileImg ${(selectedCheckboxListForNewOpen &&
								selectedCheckboxListForNewOpen?.length > 0) ||
								(selectedCheckboxList && selectedCheckboxList?.length > 0)
								? "selectedFilterButton"
								: ""
								}`}
							onClick={() => setOpenFilterSlider(true)}
						>
							<img
								src={
									(selectedCheckboxListForNewOpen &&
										selectedCheckboxListForNewOpen?.length > 0) ||
										(selectedCheckboxList && selectedCheckboxList?.length > 0)
										? iconFilterWhite
										: iconFilter
								}
								alt="Filter icon"
							/>
							Filter{" "}
							{(selectedCheckboxListForNewOpen &&
								selectedCheckboxListForNewOpen?.length > 0) ||
								(selectedCheckboxList && selectedCheckboxList?.length > 0)
								? selectedCheckboxList && selectedCheckboxList?.length > 0
									? `(${selectedCheckboxList?.length})`
									: ""
								: ""}
						</div>

						<div
							className={`navbar-pageHeader-profileImg 
								${radioAscFilter === "" && radioValueFilter === ""
									? ""
									: radioAscFilter === "Ascending" &&
										radioValueFilter === "date"
										? ""
										: "selectedFilterButton"
								}
							`}
							onClick={() => setOpenSortSlider(true)}
						>
							<img
								src={
									radioAscFilter === "" && radioValueFilter === ""
										? iconSort
										: radioAscFilter === "Ascending" &&
											radioValueFilter === "date"
											? iconSort
											: iconSortWhite
								}
								alt="Sort icon"
							/>{" "}
							Sort
						</div>

						{add ? (
							<div
								className="navbar-pageHeader-profileImg"
								onClick={() => {
									buttonToggle();
								}}
							>
								<img src={iconschecked_checkbox} alt="Select icon" /> Select
							</div>
						) : (
							<div
								className="navbar-pageHeader-profileImg selectedFilterButton"
								onClick={() => {
									selectToggle();
								}}
							>
								<img src={iconCancelWhite} alt="Cancel icon" /> Cancel
							</div>
						)}
					</div>

					{/* <section className="timeSheet-blankState">
						<div className="timeSheet-blankState__icon timeSheet-blankState__icon-green"><img src={iconCoffee} alt="coffee icon" /></div>	
						<div className="timeSheet-blankState__heading">You're all caught up!</div>	
						<div className="timeSheet-blankState__subHeading">Time to enjoy a nice cup of coffee or tea.</div>	
						<div className="timeSheet-blankState__description">Don't forget to can view all timesheets past and present by clearing all applied filters.</div>	
					</section> */}
					{/* <section className="timeSheet-blankState">
						<div className="timeSheet-blankState__icon timeSheet-blankState__icon-red"><img src={iconTimeSearch} alt="coffee icon" /></div>	
						<div className="timeSheet-blankState__heading">There's nothing here!</div>	
						<div className="timeSheet-blankState__subHeading">Filters help you narrow down your search</div>	
						<div className="timeSheet-blankState__description">Don't forget to can view all timesheets past and present by clearing all applied filters.</div>	
					</section> */}
					<section className="main-card">
						{filteredResults.length > 0 ? (
							filteredResults.map((item, index) => (
								<div className={style} key={item.id}>
									{isChecked ? (
										<TagCheckbox
											rounded
											name="nr"
											id={item.id}
											checkboxId={item.id}
											className="main-card-checkbox"
											size="normal"
											accent="navy"
											colourScheme="default"
											onCheckboxValueChange={handleClick}
											//   onCheckboxValueChange={handleCheckboxChange}
											checked={isCheck.includes(item.id)}
										/>
									) : null}

									<div
										className="main-cards 11"
										onClick={() => redirectToDetails(item.name, item.status)}
									>
										{display ? (
											item.isVisited === "true" ? (
												<div className="main-card-dot"></div>
											) : null
										) : null}
										<div
											className={`main-card-body ${add && item.isVisited === "true" ? "leftSpacing" : ""
												}`}
										>
											<div className="main-card-name">
												<h3>{item.name}</h3>
												<span
													className={
														item.status === "Approved"
															? "main-card-approved-status"
															: "main-card-status"
													}
												>
													{item.status}
												</span>
											</div>
											<h4>{item.category}</h4>
											<h5>{item.date}</h5>
											<span className="main-card-hours">
												{item.hours} hours
											</span>
											<span className="main-card-pay">
												&nbsp;£{item.pay} hourly
											</span>
											<span className="main-card-date">{item.entryDate}</span>
										</div>
									</div>
								</div>
							))
						) : (
							<section className="timeSheet-blankState">
								<div className="timeSheet-blankState__icon timeSheet-blankState__icon-red"><img src={iconTimeSearch} alt="coffee icon" /></div>	
								<div className="timeSheet-blankState__heading">There's nothing here!</div>	
								<div className="timeSheet-blankState__subHeading">Filters help you narrow down your search</div>	
								<div className="timeSheet-blankState__description">Don't forget to can view all timesheets past and present by clearing all applied filters.</div>	
							</section>
						)}
					</section>

					{add ? (
						<PageFooter />
					) : (
						<div className="timesheet-pageFooter">
							<div className="timesheet-pageFooter-nav"  data-testId="approveButton">
								<TagButton
									className="customButton"
									accent="keppel"
									text="Approve"
									icon={iconCheckCircle}
									size="default"
									device="mobile"
									buttonIconStyle={{ height: "16px", width: "16px" }}
								/>
							</div>

							<div className="timesheet-pageFooter-nav" data-testId="amendButton">
								<TagButton
									className="customButton"
									id="submitButton"
									accent="navy"
									text="Amend"
									disabled={isCheck.length > 0 ? false : true}
									icon={iconEdit}
									size="default"
									device="mobile"
									buttonIconStyle={{ height: "16px", width: "16px" }}
									onButtonClick={() => navigate("/amend-timesheet")}
								/>
							</div>
						</div>
					)}
				</>
			)}
			{/* Sort Timesheet Popover */}
			{openSortSlider && (
				<TimeSheetSortFilter onDismiss={() => setOpenSortSlider(false)} />
			)}
			{openFilterSlider && (
				<TimeSheetFilter
					onDismiss={() => setOpenFilterSlider(false)}
					checkboxArray={uniqueAllowedCategory}
				/>
			)}
		</motion.div>
	);
};

export default Timesheet;
