import { createSlice } from "@reduxjs/toolkit";

const timeTrackerIntialState = {
    timeTrackerStatusDetails: "",
    timeTrackerFilterList:[]
}

const timeTrackerSlice = createSlice({
    name: "timeTrackerDetails",
    initialState: timeTrackerIntialState,
    reducers: {
        setTimeTrackerStatusDetails:(state, {payload}) => {
            state.timeTrackerStatusDetails = payload;
        },
        setTimeTrackerFilterList: (state, {payload}) => {
            state.timeTrackerFilterList = payload.timeTrackerFilterList;
        }
    }
})

export const  {setTimeTrackerStatusDetails, setTimeTrackerFilterList} = timeTrackerSlice.actions;
export const allTimeTrackerDetails= (state: any) => state.timeTrackerDetails;
export default  timeTrackerSlice.reducer;