import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowBack from "../../images/angle_left.svg";
import refresh from "../../images/refresh.svg";
import edit from "../../images/edit.svg";
import "./TimesheetDetail.scss"
import { TagButton, TagMobileSliderPopover, TagTextarea } from "@tag/tag-components-react-v3";
import TimesheetDetailsSkeleton from "./TimesheetDetailsSkeleton";
import { motion } from "framer-motion";
import { containerVariants } from "../../types/constant";
import { useSelector } from "react-redux";
import { getAllTimeSheetDetails } from "./TimesheetDetailSlice";
import { getTimesheetDetail } from "../../api/api";

export default function TimesheetDetail() {
    const [httpStatusCode, setHttpStatusCode] = React.useState(0);
    const [getdetaildata, setTimesheetDetail] = useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);


      useEffect(() => {
       
        getApi();
        
    }, []);


    async function getApi() {
        try {
            const res = await getTimesheetDetail()
            //const {data, status} = (await res).data;
            if (Object.keys(res.data).length && res.status === 200) {
                // received api data successfully
                
                if (res.data) {
                    setLoading(false);
                }
                setTimesheetDetail(res.data);
                setHttpStatusCode(res.status);
              }
            
            
        } catch (err) {
            console.log("err:", err);
            return [];
        }
    }
    const { timeSheetDetailName, timeSheetDetailStatus } = useSelector(getAllTimeSheetDetails);
    const navigate = useNavigate();
    const onBackClick = () => {
        navigate("/timesheet")
    }

    const [openPopup, setOpenPopup] = React.useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false)
        }, 500);
        return () => clearTimeout(timer)
    }, []);

    const [val, setVal] = useState("");
    const resetInputField = () => {
        setOpenPopup(false);
        setVal("");
      };
    return (
        <motion.div
            className="motionDiv"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
        >
            {showSkeleton ? <TimesheetDetailsSkeleton /> :
                <>
                    <div className="timeDetails_header">
                        <div className="timeDetails_header_back">
                            <img src={arrowBack} className="timeDetails_back" alt={arrowBack}  onClick={() => onBackClick()} />
                        </div>
                        <div className="timeDetails_header_title" data-testid="headTitle">
                            Timesheet
                        </div>
                        <Link to="/timesheet-history">
                            <div className="timeDetails_header_action">
                                <img src={refresh} className="timeDetails_action_size" alt={arrowBack} />
                            </div>
                        </Link>
                        <Link to="/edit-timesheets">
                            <div className="timeDetails_header_action">
                                <img src={edit} className="timeDetails_action_size" alt={arrowBack} />
                            </div>
                        </Link>
                    </div>

                    <div className="timeDetails_content">
                        <div className="timeDetails_content_grey">
                            <div className="recruiter_card">
                                <div className="recruiter_card_body">
                                    <div className="recruiter_card_body_name">
                                        {timeSheetDetailName} <span className={timeSheetDetailStatus === "Approved"
                                            ? "main-card-approved-status"
                                            : "recruiter_card_body_status"}>{timeSheetDetailStatus}</span>
                                    </div>
                                    <p className="recruiter_card_body_dept">Food Picking Inc</p>
                                    <p className="recruiter_card_body_date">17th May - 23th May,2020</p>
                                    <div className="recruiter_card_body_hours"><b>7.5 hours
                                        <span>£8 hourly</span></b>
                                        <p>01 Dec</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="timeDetails_content_table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                {getdetaildata.map((item) => (
                                    <tr key={item.id}>
                                        <td data-label="Number">
                                            <div className="timeDetails_content_table_date">{item.date.date}</div>
                                            <div className="timeDetails_content_table_day">{item.date.day}</div>
                                        </td>
                                        <td data-label="Name">{item.startTime} </td>
                                        <td data-label="Phone">{item.endTime} </td>
                                        <td data-label="Date">
                                            <div className="timeDetails_content_table_total">{item.total}</div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="timeDetails_footer">
                        <div className="timeDetails_footer_total">
                            <div className="timeDetails_footer_total_text">
                                Total
                            </div>
                            <div className="timeDetails_footer_total_text">
                                27:30
                            </div>
                        </div>
                        <div className="timeDetails_footer_btn_approve" data-testid="approveTimesheet">
                            <TagButton
                                
                                className="customButton"
                                text="Approve timesheet"
                                device="mobile"
                                accent="keppel"
                                onButtonClick={() => navigate("/timesheet")}
                            >
                            </TagButton>
                        </div>
                        <div className="timeDetails_footer_btn_query" data-testid="queryTimesheet">
                            <TagButton
                                
                                device="mobile"
                                text="Query timesheet"
                                accent="navy"
                                className='customButton'
                                onButtonClick={() => setOpenPopup(true)}
                            ></TagButton>
                        </div>

                    </div>
                    <TagMobileSliderPopover
                        open={openPopup}
                        className="queryPopover"
                        heading="Query John’s timesheet"
                        initialHeight="450px"
                        colour-scheme="light"
                        onClosed={() => setOpenPopup(false)}
                    >
                        <div className="timesheetQuery">
                            <div className="timesheetQuery_date">
                                <div>17th May - 23th May, 2020</div>
                                <p className="timesheetQuery_label">Message</p>
                                <TagTextarea
                                    textareaId="textAreaId"
                                    placeholder="Hi John, didnt you leave early on Monday?"
                                    name="exampleName"
                                    onTextareaValueChange={(e) => setVal(e.detail.value)}
                                    value={val ? val : ""}
                                    className="timesheetQuery_textarea" />
                            </div>
                            <div className="timesheetQuery_buttons">
                                <div className="timesheetQuery_buttons_flex">
                                    <div className="timesheetQuery_buttons_flex_btn">
                                        <TagButton
                                            className="customTernaryButton"
                                            text="Cancel"
                                            size="default"
                                            device="mobile"
                                            onButtonClick={() => resetInputField()}
                                        ></TagButton>
                                    </div>
                                    <div className="timesheetQuery_buttons_flex_btn">
                                        <TagButton
                                            device="mobile"
                                            text="Send Query"
                                            accent="navy"
                                            className="customButton"
                                            onButtonClick={() => navigate("/timesheet-history")}
                                        ></TagButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TagMobileSliderPopover>
                </>
            }
        </motion.div>
    )
}