import { configureStore } from "@reduxjs/toolkit";
import checkboxFilterReducer from "../components/Timesheet/TimeSheetFilterSlice";
import radioSortReducer from "../components/Timesheet/FilterRadioSlice";
import timeSheetDetailReducer from "../components/TimesheetDetail/TimesheetDetailSlice";
import timeTrackerDetailsReducer from "../components/TimeTracker/TimeTrackerSlice";
import placementDetailsReducer from "../components/PlacementDetails/PlacementDetailsSlice";
import SelectAppSlice from "../components/ManualWorkerCheckIn/SelectApp";
import ExportReport from "../components/ExportAttendanceReport/ExportAttendanceSlice";
import MyAccount from "../components/MyAccount/MyAccountSlice";
import createOidcMiddleware from "redux-oidc";
import { reducer as oidc } from "redux-oidc";
import userManager from "../utils/userManager";

const oidcMiddleware = createOidcMiddleware(userManager);

export const store = configureStore({
  reducer: {
    checkboxFilters: checkboxFilterReducer,
    radioFilters: radioSortReducer,
    timeSheetSlice: timeSheetDetailReducer,
    timeTrackerDetails: timeTrackerDetailsReducer,
    placementSlice: placementDetailsReducer,
    selectappSlice: SelectAppSlice,
    ExportReport: ExportReport,
    MyAccount: MyAccount,
    oidc,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, oidc: oidcMiddleware }),
});
