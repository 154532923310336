import React, { useEffect, useState } from 'react'

import {
	TagButton,
	TagMobileAlert,
	TagMobileSliderPopover,
	TagRadioButton,
	TagRadioButtons,
} from '@tag/tag-components-react-v3'
import './ManualWorkerCheckIn.scss'
import tagicon from '../../images/priceicon.svg'
import exclamation from '../../images/exclamation.svg'
import iconPlus from '../../images/iconPlus.svg'
import iconMinus from '../../images/iconMinus.svg'
import backarrow from '../../images/angle_right.svg'
import infoentity from '../../images/infoentitymanual.svg'
import iconCheck from '../../images/icon_check.svg'
import iconRemove from '../../images/iconRemove.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSelectApp, setSelectAppName } from './SelectApp'
import { getAllSelectAppsData } from '../../api/api'

type Task = {
	id: number
	task: string
}

let totalTime = 0;
let id = 0;
export default function ManualWorkerCheckIn(props: any) {
	const { onDismiss } = props

	const [manualWorkerCheckIn, setmanualWorkerCheckIn] = React.useState(true)
	const dispatch = useDispatch()
	const [_httpStatusCode, setHttpStatusCode] = React.useState(0)
	const [getSelectAppData, setSelectAppData] = useState<any[]>([])
	const [hideSelectApp, setHideSelectApp] = React.useState(true)
 
	const [_loading, setLoading] = React.useState(true)
	useEffect(() => {
		getSelectAppsApi()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	async function getSelectAppsApi() {
		try {
			const res = await getAllSelectAppsData()
			if (Object.keys(res?.data).length && res?.status === 200) {
				// received api data successfully

				if (res.data) {
					setLoading(false)
				}
				setSelectAppData(res.data)
				setHttpStatusCode(res.status)
			} else {
				setLoading(false)
				setHttpStatusCode(res!.status)
			}
		} catch (err) {
			console.log('err:', err)
			return []
		}
	}
	const manualCheckIn = () => {
		if(!radioSelectLocation){
			setHideSelectApp(true)
		
		}
		setmanualWorkerCheckIn(true)
		// setShowSelectApp(true)
	}

	const onSelectApp = () => {
		const payload = {
			radioSelectLocation: applyselectbox,
		}
		dispatch(setSelectAppName(payload))
		setmanualWorkerCheckIn(true)
		
	}

	const saveCheckIn= () =>{
		const payload = {
			radioSelectLocation: "",
		}
		 dispatch(setSelectAppName(payload))
			onDismiss();
			
		 
		 
	}
	const { radioSelectLocation } = useSelector(getAllSelectApp)
	const [applyselectbox,setApplySelectBox] = React.useState(radioSelectLocation)
	const selectApp = () => {
		setHideSelectApp(false)
		setmanualWorkerCheckIn(false)
	}
	// ADD BREAK FUNCTIONALITY
	
	const [addClick, removeClick] = useState(true)
	const [isBack, setIsBack] = useState(true)
	const [isCustom, setIsCustom] = useState(true)
	const [input, setInput] = useState<string>('')
	const [list, setList] = useState<Task[]>([])
	const [showList, setShowList] = useState(
		'manual_checkin_content_breaks_showlists',
	)
	const [showToast, setShowToast] = React.useState(false)

	const updateStatus = (status: string) => {
		const time = totalTime + parseInt(status)
		if (time <= 480) {
			const task: Task = { id: id, task: status }
			setList([...list, task])
			id++
			setShowList('manual_checkin_content_breaks_lists')
			totalTime = totalTime + parseInt(status)
		} else {
			setShowToast(true)
		}
	}

	const removeTask = (id: number): void => {
		const newList: Task[] = list.filter((_, index) => index !== id)
		totalTime = totalTime - parseInt(list[id].task)
		setList(newList)
	}

	function addBreak() {
		setIsCustom(true)
		removeClick(!addClick)
		id++
	}

	function cancelCustom() {
		setIsCustom(true)
		removeClick(false)
		setInput('')
	}

	function customClickable() {
		setIsCustom(!isCustom)
	}

	function addCustomBreak() {
		const time = totalTime + parseInt(input)
		if (time <= 480) {
			setIsBack(!isBack)
			const task: Task = { id: id, task: input }
			setList([...list, task])
			setIsCustom(true)
			removeClick(true)
			id++
			setShowList('manual_checkin_content_breaks_lists')
			totalTime = totalTime + parseInt(input)
			setInput('')
		} else {
			setShowToast(true)
		}
	}

	return (
		<>
			{manualWorkerCheckIn ? (
				<TagMobileSliderPopover
					open={true}
					heading="Manual Worker Check-in"
					initialHeight="80%"
					onClosed={() => onDismiss()}
					className="manualWorker customPopover"
					data-testid="manualcheckin"
				>
					<div className="manual_checkin">
						<div className="manual_checkin_content">
							{hideSelectApp ? (
								<div
									className="manual_checkin_content_selectapp"
									onClick={() => selectApp()}
								>
									<div className="manual_checkin_content_selectapp_texts">
										<div className="manual_checkin_content_selectapp_texts_box">
											<img
												className="manual_checkin_content_selectapp_texts_box_tags"
												src={tagicon}
												alt="tagicon"
											/>
											<img
												className="manual_checkin_content_selectapp_texts_box_info"
												src={exclamation}
												alt="exclamation"
											/>
										</div>
										<div className="manual_checkin_content_selectapp_texts_select">
											<b>Select app</b>
											<p>Please select the domain for the worker</p>
										</div>
									</div>

									<div className="manual_checkin_content_selectapp_clickable">
										<img src={backarrow} alt="Go back arrow" />
									</div>
								</div>
							) : (
								<div
									className="manual_checkin_content_selectapp"
									onClick={() => selectApp()}
								>
									<div className="manual_checkin_content_selectapp_texts">
										<div className="manual_checkin_content_selectapp_texts_box">
											<img
												className="manual_checkin_content_selectapp_texts_box_tags"
												src={iconCheck}
												alt="tagicon"
											/>
										</div>
										<div className="manual_checkin_content_selectapp_texts_select">
											<b>{radioSelectLocation}</b>
											<p>Click to change app</p>
										</div>
									</div>

									<div className="manual_checkin_content_selectapp_clickable">
										<img src={backarrow} alt="Go back arrow" />
									</div>
								</div>
							)}

							<div className="manual_checkin_content_breaks">
								<div className="manual_checkin_content_breaks_label">
									Check in
								</div>
								<div className="manual_checkin_content_breaks_inputControl">
									<div className="manual_checkin_content_breaks_input">
										<input
											type="text"
											value="09:00"
											className="manual_checkin_content_breaks_inputBox  text-center"
										/>
										<span className="manual_checkin_content_breaks_actionIcon manual_checkin_content_breaks_plusIcon">
											<img src={iconPlus} alt="Plus Icon" />
										</span>
										<span className="manual_checkin_content_breaks_actionIcon">
											<img src={iconMinus} alt="Minus Icon" />
										</span>
									</div>
								</div>

								<div className="manual_checkin_content_breaks_label">
									Check out
								</div>
								<div className="manual_checkin_content_breaks_inputControl">
									<div className="manual_checkin_content_breaks_input">
										<input
											type="text"
											value="09:00"
											className="manual_checkin_content_breaks_inputBox  text-center"
										/>
										<span className="manual_checkin_content_breaks_actionIcon manual_checkin_content_breaks_plusIcon">
											<img src={iconPlus} alt="Plus Icon" />
										</span>
										<span className="manual_checkin_content_breaks_actionIcon">
											<img src={iconMinus} alt="Minus Icon" />
										</span>
									</div>
								</div>
								<div className="manual_checkin_content_breaks_label">
									Breaks
								</div>

								<div className="manual_checkin_content_breaks">
									{addClick ? (
										<div>
											<div className={showList}>
												<ul className="manual_checkin_content_breaks_lists">
													{list &&
														list.map((task: Task, index) => (
															<li key={index}>
																{task.task} {''}
																{parseInt(task.task) > 1 ? ' mins' : 'min'}
																{''}
																<button onClick={() => removeTask(index)}>
																	<img src={iconRemove} alt="Profile img" />
																</button>
															</li>
														))}
												</ul>
											</div>
											<div
												className="manual_checkin_content_breaks_buttons"
												onClick={() => {
													addBreak()
												}}
											>
												<TagButton
													device="mobile"
													text="Add Break"
													accent="navy"
													className="customButtonNew"
												></TagButton>
											</div>
										</div>
									) : (
										<div className="manual_checkin_content_breaks_buttons">
											<div className="manual_checkin_content_breaks">
												{isCustom ? (
													<div>
														<div className="manual_checkin_content_breaks_list">
															<div className={showList}>
																<ul className="manual_checkin_content_breaks_lists">
																	{list &&
																		list.map((task: Task, index) => (
																			<li key={index}>
																				{/* {task.task} mins */}
																				{task.task} {''}
																				{parseInt(task.task) > 1
																					? ' mins'
																					: 'min'}
																				{''}
																				<button
																					onClick={() => removeTask(index)}
																				>
																					<img
																						src={iconRemove}
																						alt="Profile img"
																					/>
																				</button>
																			</li>
																		))}
																</ul>
															</div>
														</div>
														<div className="manual_checkin_content_breaks_list">
															<div className="manual_checkin_content_breaks_list_lists">
																<div
																	className="manual_checkin_content_breaks_list_lists_box"
																	onClick={() => updateStatus('15')}
																>
																	<span>15 min</span>
																</div>
																<div
																	className="manual_checkin_content_breaks_list_lists_box"
																	onClick={() => updateStatus('30')}
																>
																	<span>30 min</span>
																</div>
																<div
																	className="manual_checkin_content_breaks_list_lists_box"
																	onClick={() => updateStatus('60')}
																>
																	<span>60 min</span>
																</div>

																<div
																	className="manual_checkin_content_breaks_list_lists_box"
																	onClick={() => {
																		customClickable()
																	}}
																>
																	<span>Custom</span>
																</div>
															</div>
														</div>
														<div
															onClick={() => {
																addBreak()
															}}
														>
															<TagButton
																className="customTernaryButton"
																text="Cancel Break"
																size="default"
																device="mobile"
															></TagButton>
														</div>
													</div>
												) : (
													<div className="manual_checkin_content_breaks_input">
														<div>
															<input
																type="number"
																placeholder="Enter break minutes"
																className="manual_checkin_content_breaks_inputBox manual_checkin_content_breaks_break"
																value={input}
																onChange={(e) => {
																	const regx = e.target.value.replace(
																		/[\.]/g,
																		'',
																	)
																	if (
																		regx.length === 4 ||
																		parseInt(e.target.value) === 0
																	)
																		return false //limits to 2 digit entry
																	//saving input to state
																	setInput(regx)
																}}
																// replace(/[0/^d/[a-zA-Z]]/g,
																required
															/>
															<span className="manual_checkin_content_breaks_minutestxt">
																Minutes
															</span>
															<span
																className="manual_checkin_content_breaks_actionIcon manual_checkin_content_breaks_plusIcon heightAuto"
																onClick={() => {
																	addCustomBreak()
																}}
															>
																<button
																	disabled={!input}
																	className="manual_checkin_content_breaks_actionIcon manual_checkin_content_breaks_plusIcon_text heightAuto"
																>
																	Add Break
																</button>
															</span>
														</div>
														<div
															onClick={() => {
																cancelCustom()
															}}
														>
															<TagButton
																className="customTernaryButton"
																text="Cancel Break"
																size="default"
																device="mobile"
															></TagButton>
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
							{hideSelectApp ? (
								<div className="manual_checkin_content_appmessage">
									<div className="manual_checkin_content_appmessage_icon">
										<img src={infoentity} alt="info icon"></img>
										<span>An app must be selected</span>
									</div>
								</div>
							) : (
								''
							)}
						</div>

						<div className="manual_checkin_footer">
							<div className="manual_checkin_footer__total">
								<div className="manual_checkin_footer__total_text">Total</div>
								<div className="manual_checkin_footer__total_text">7:30</div>
							</div>

							<div className="manual_checkin_footer_btnGrid">
								<div
									className="manual_checkin_footer_btn"
									onClick={() => saveCheckIn()}
								>
									<TagButton
										className="customTernaryButton"
										text="Cancel"
										size="default"
										device="mobile"
									></TagButton>
								</div>

								<div
									className="manual_checkin_footer_btn manual_checkin_footer_btn__filled "
									onClick={() => saveCheckIn()}
								>
									<TagButton
										device="mobile"
										text="Save Check-in"
										accent={`${!hideSelectApp ? "navy" : "porcelain"}`}
										className={`customButton ${!hideSelectApp ? "checkin":"custonButton"}`}
									></TagButton>
								</div>
							</div>
						</div>
					</div>
				</TagMobileSliderPopover>
			) : (
				// Select APP SECTION

				<TagMobileSliderPopover
					open={true}
					heading="Select app"
					initialHeight="500px"
					onClosed={() => onDismiss()}
					className="selectApp customPopover"
				>
					<div className="select_app">
						<div className="select_app_content">
							{getSelectAppData.map((appdata: any) => (
								<TagRadioButtons
									accent="navy"
									name="radioBtn"
									colourScheme="default"
									value={radioSelectLocation}
									device="mobile"
									orientation="vertical"
									key={appdata.id}
									// onRadioValueChange={() => setApplySelectBox(appdata.appname)}
									 onClick={() => setApplySelectBox(appdata.appname)}
									
								>
									<TagRadioButton value={appdata.appname}
									checked={radioSelectLocation}>
										{appdata.appname}
									</TagRadioButton>
								</TagRadioButtons>
							))}
						</div>

						<div className="select_app_footer">
							<div className="select_app_footer_btnGrid">
								<div
									className="select_app_footer_btn"
									onClick={() => manualCheckIn()}
								>
									<TagButton
										className="customTernaryButton"
										text="Back"
										size="default"
										device="mobile"
									></TagButton>
								</div>

								<div
									className="select_app_footer_btn select_app_footer_btn__filled "
									
								>
									<TagButton
										device="mobile"
										text="Apply app"
										accent="navy"
										className="customButton"
										
										disabled = {applyselectbox.length === 0}
										onButtonClick={() => onSelectApp()}
									></TagButton>
								</div>
							</div>
						</div>
					</div>
				</TagMobileSliderPopover>
			)}

			<div className={showToast ? 'z-indexed' : ''}>
				{showToast ? (
					<>
						<TagMobileAlert
							className="custom_alert"
							size="fullWidth"
							showDuration={0}
							closeable
							closeTransition="slideDown"
						>
							<div className="div_alert">
								<span className="span_alert">
									Limit Exceeds (Limit=480 mins)
								</span>
							</div>
						</TagMobileAlert>
					</>
				) : null}
			</div>
		</>
	)
}
