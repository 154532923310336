import React, { useState } from "react";
import "./Timesheet.scss";
import PageFooter from "../PageFooter/PageFooter";
import iconSearch from "../../images/iconSearch.svg";
import { TagSkeleton } from "@tag/tag-components-react-v3";

const TimesheetSkeleton = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [style, _setStyle] = useState("main-card-containerBox");
	return (
		<>
			<div className="navbar-main-pageHeader">
				<div className="navbar-main-pageHeader-title">
					<b>Timesheets</b>
				</div>
				<div className="navbar-main-pageHeader-profileImg">
					<img
						src={iconSearch}
						alt="Search icon"
						className="navbar-main-pageHeader-icon-size"
					/>
				</div>
			</div>

			<div className="navbar-pageHeader">
				<div className="navbar-pageHeader-title">
					<TagSkeleton height="15px" width="100px" ></TagSkeleton>
				</div>
				
				<div className="navbar-pageHeader-profileImg">
					<TagSkeleton height="10px" width="50px" itemStyle={{ display: "inline-block" }}></TagSkeleton>
				</div>

				<div className="navbar-pageHeader-profileImg">
					<TagSkeleton
						height="10px"
						width="50px"
						itemStyle={{ display: "inline-block" }}
					></TagSkeleton>
				</div>
			</div>

			<section className="main-card">
			{Array(8)
					.fill(0)
					.map((key, index) => (
					<div className={style} key={index}>
						<div className="main-cards">
							<div className="main-card-body">
								<div className="main-card-name">
									<h3>
										<TagSkeleton height="10px" width="150px" itemStyle={{ marginBottom: "8px" }}></TagSkeleton>
									</h3>
									<span className="main-card-status">
										<TagSkeleton height="10px" width="100px"></TagSkeleton>
									</span>
								</div>
								<h4>
									<TagSkeleton
										height="10px"
										width="150px"
										itemStyle={{ marginBottom: "8px" }}
									></TagSkeleton>
								</h4>
								<h5>
									<TagSkeleton
										height="10px"
										width="150px"
										itemStyle={{ marginBottom: "8px" }}
									></TagSkeleton>
								</h5>
								<span className="main-card-hours">
									<TagSkeleton
										height="10px"
										width="50px"
										itemStyle={{
											marginRight: "8px",
											display: "inline-block",
											marginBottom: "0"
										}}
									></TagSkeleton>
								</span>
								<span className="main-card-pay">
									{" "}
									<TagSkeleton
										height="10px"
										width="50px"
										itemStyle={{
											marginRight: "8px",
											display: "inline-block",
											marginBottom: "0"
										}}
									></TagSkeleton>
								</span>
								<span className="main-card-date">
									<TagSkeleton height="10px" width="50px" itemStyle={{ marginBottom: "0" }} ></TagSkeleton>
								</span>
							</div>
						</div>
					</div>
				))}
			</section>
			<PageFooter />
		</>
	);
};

export default TimesheetSkeleton;
