import axios from "axios";

export const apiUrl = axios.create({
  baseURL: 'http://localhost:3001',
});

const API = {
	notifications: "http://localhost:3001/notifications/"
}

const getAllNotifications = async () => {
	return axios.get("http://localhost:3001/notifications/");
}

const getAllTimesheets = async (value?: boolean) => {
	return  axios.get("http://localhost:3001/timesheet/");
}

const getAllSelectAppsData = async (_value?: boolean) => {
	return  axios.get("http://localhost:3001/selectapp/");
}
const getNotification = async (id: string) => {
	const res = await fetch(`http://localhost:3001/notifications/${id}`);
	const data = await res.json();
	return { "data": data, "status": res.status };
}


const updateMyAccountData = async (data: {}, id: number) => {
	return axios.put(`http://localhost:3001/myAccount/${id}`, data,{
		headers:{
			'Content-Type': 'application/json'
		}
	})
}

const getAccountData = async (id: number) => {
	return  axios.get(`http://localhost:3001/myAccount/${id}`);
}
const deleteNotificationFunction = async (deleteData: any[]) => {
	let result: any;
	for(var i = 0;i<deleteData.length;i++) {
		const res = axios.delete(`http://localhost:3001/notifications/${deleteData[i]}`);
		result= res;
	}
	return result;
}

const deleteSingleNotificationFunction = async (deleteId: any) => {
	return axios.delete(`http://localhost:3001/notifications/${deleteId}`);
}

const getAllEditTimesheet = async () => {
	return axios.get("http://localhost:3001/edittimesheet/");
}
const getTimesheetDetail = async () => {
	return axios.get("http://localhost:3001/timesheetdetail/");
}
const getTimeTrackers = async () => {
	return axios.get("http://localhost:3001/timetrackers/");
}

export { 
	getAllNotifications,
	getNotification,
	getAccountData, 
	updateMyAccountData, 
	deleteNotificationFunction, 
	getAllTimesheets,
	getAllEditTimesheet,
	getTimesheetDetail,
	deleteSingleNotificationFunction,
	getTimeTrackers,
	getAllSelectAppsData };
export default API;
