import { createUserManager } from "redux-oidc";

const appRootUrl = `http://localhost:8080`;

const userManagerConfig = {
  client_id: "access.recruitmentmobile.hirer",
  redirect_uri: `${appRootUrl}/auth/signinwin/${"main"}`,
  response_type: "code",
  scope: "openid profile email policy access.hirer.api",
  authority: "https://preprodidentity.accessacloud.com/",
  silent_redirect_uri: `${appRootUrl}/auth/signinsilent/${"main"}`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
