import { useEffect, useState } from "react";
import PageFooter from "../PageFooter/PageFooter";
import PageHeader from "../PageHeader/PageHeader";
import homeImg from "../../images/homeImg.svg";
import iconTimesheet from "../../images/iconTimesheet.svg";
import iconAllTimeSheet from "../../images/iconAllTimeSheet.svg"
import iconNewTimeSheet from "../../images/iconNewTimeSheet.svg"
import iconRight from "../../images/iconRight.svg";
import HomeSkeleton from "./HomeSkeleton";
import "./Home.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { containerVariants } from "../../types/constant";
import { useSelector } from "react-redux";
import userManager from "../../utils/userManager";
export default function Home() {
    const [showSkeleton, setShowSkeleton] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    const isAuthenticated = useSelector((state: any) => state.oidc.user && !state.oidc.user.expired);
    if (!isAuthenticated)
        userManager.signinRedirect();
        
    return (
        <motion.div
            className="motionDiv"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
        >
            <PageHeader />
            {showSkeleton ? <HomeSkeleton /> :
                <div className="App-page">
                    <div className="homePage">
                        <div className="homePage-img">
                            <img src={homeImg} alt="home img" />
                        </div>
                        <Link to="/timesheet" className="homePage-link">
                            <div className="homePage-card_viewtimesheet">
                                <div className="homePage-card_viewtimesheet_viewtimesheets">
                                    <img
                                        src={iconNewTimeSheet}
                                        alt="Timesheet icon"
                                        className="homePage-card__icon-size"
                                    />
                                    <div className="homePage-card_viewtimesheet_viewtimesheets_title">
                                        14 New
                                    </div>
                                </div>
                                <div className="homePage-card__righticon">
                                    <img
                                        src={iconRight}
                                        alt="Right Icon"
                                    />
                                </div>
                            </div>
                            <div className="homePage-card_viewtimesheet">
                                <div className="homePage-card_viewtimesheet_viewtimesheets">
                                    <img
                                        src={iconTimesheet}
                                        alt="Timesheet icon"
                                        className="homePage-card__icon-size"
                                    />
                                    <div className="homePage-card_viewtimesheet_viewtimesheets_title">
                                        12 Need action
                                    </div>
                                </div>
                                <div className="homePage-card__righticon">
                                    <img
                                        src={iconRight}
                                        alt="Right Icon"
                                    />
                                </div>
                            </div>

                            <div className="homePage-card_viewtimesheet">
                                <div className="homePage-card_viewtimesheet_viewtimesheets">
                                    <img
                                        src={iconAllTimeSheet}
                                        alt="Timesheet icon"
                                        className="homePage-card__icon-size"
                                    />
                                    <div className="homePage-card_viewtimesheet_viewtimesheets_title"  data-testid="viewAllTimesheets">
                                        View all timesheets
                                    </div>
                                </div>
                                <div className="homePage-card__righticon">
                                    <img
                                        src={iconRight}
                                        alt="Right Icon"
                                    />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            }
            <PageFooter />
        </motion.div>
    )
}
