import { useEffect, useState } from "react";
import "./TimesheetHistory.scss";
import arrowBack from "../../images/angle_left.svg";
import PageFooter from "../PageFooter/PageFooter";
import TimesheetHistorySkeleton from "./TimesheetHistorySkeleton";
import { useNavigate } from "react-router-dom";

export default function TimesheetHistory() {
    const [showSkeleton, setShowSkeleton] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const navigate = useNavigate();
    const onBackClick = () => {
        navigate("/timesheet-details");
    };


    return (
        <>
            {showSkeleton ? (
                <TimesheetHistorySkeleton />
            ) : (
                <>
                    <div className="timeDetails_header">
                        <div className="timeDetails_header_back">
                            <img
                                src={arrowBack}
                                className="timeDetails_back"
                                onClick={() => onBackClick()}
                                alt={arrowBack}
                            />
                        </div>
                        <div className="timeDetails_header_title">Timesheet history</div>
                    </div>
                    <div className="App-page">
                        <div className="history_card">
                            <div className="history_card_body">
                                <div className="history_card_body_head">
                                    Timesheet approved <span>1 Dec</span>
                                </div>
                                <p className="history_card_body_name">By James Andrews</p>
                            </div>

                            <div className="history_card_body">
                                <div className="history_card_body_head">
                                    Timesheet amended <span>30 Nov</span>
                                </div>
                                <p className="history_card_body_name">By James Andrews</p>
                            </div>

                            <div className="history_card_body">
                                <div className="history_card_body_head">
                                    Timesheet query<span>28 Nov</span>
                                </div>
                                <p className="history_card_body_name">By James Andrews</p>
                                <p className="history_card_body_message">Hi Jennifer, didn’t you leave early on Monday?</p>
                            </div>
                            <div className="history_card_body">
                                <div className="history_card_body_head">
                                    Timesheet submitted <span>29 Nov</span>
                                </div>
                                <p className="history_card_body_name">By James Andrews</p>
                            </div>
                        </div>
                    </div>
                    <PageFooter />
                </>
            )}
        </>
    );
}
