import "./App.scss";
import Home from "./components/Home/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import EditTimesheet from "./components/EditTimesheet/EditTimesheet";
import GetStarted from "./components/GetStarted/GetStarted";
import TimesheetDetail from "./components/TimesheetDetail/TimesheetDetail";
import TimesheetHistory from "./components/TimesheetHistory/TimesheetHistory";
import Timesheet from "./components/Timesheet/timesheet";
import AmendTimesheet from "./components/AmendTimesheet/AmendTimesheet";
import { AnimatePresence } from "framer-motion";
import Notifications from "./components/Notifications/Notifications";
import MyAccount from "./components/MyAccount/MyAccount";
import TimeTracker from "./components/TimeTracker/TimeTracker";
import TimeTrackerStatusDetails from "./components/TimeTracker/TimeTrackerStatusDetails/TimeTrackerStatusDetails";

import { ExportAttendanceReport } from "./components/ExportAttendanceReport/ExportAttendanceReport";
import PlacementDetails from "./components/PlacementDetails/PlacementDetails";
import CallbackPage from "./components/auth/CallbackPage";
import DebugPage from "./components/auth/DebugPage";
import userManager from "./utils/userManager";
import { connect } from "react-redux";
import { MouseEventHandler, useEffect } from "react";

function App(props: any) {
  //   if (!props.isAuthenticated) {
  //     userManager.signinRedirect();
  //   }

  const { user } = props;

  // if (!user || user.expired) userManager.signinRedirect();

  const location = useLocation();
  const onLogoutButtonClick = () => {
    userManager.signoutRedirect();
    userManager.removeUser();
  };
  const onLoginButtonClick = () => {
    userManager.signinRedirect();
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     debugger;
  //     if (!props.isAuthenticated) {
  //       userManager.signinRedirect();
  //     }
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      {console.log(props)}
      <div className="hideShow">{/* custom message */}</div>
      <button
        className="btn btn-primary"
        onClick={
          props.isAuthenticated ? onLogoutButtonClick : onLoginButtonClick
        }
      >
        {props.isAuthenticated ? "Log Out" : "Log In"}
      </button>
      <div className="App">
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<GetStarted />} />
            <Route path="/home" element={<Home />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route path="/timesheet-details" element={<TimesheetDetail />} />
            <Route path="/timesheet-history" element={<TimesheetHistory />} />
            <Route path="/edit-timesheets" element={<EditTimesheet />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/amend-timesheet" element={<AmendTimesheet />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/time-tracker" element={<TimeTracker />} />
            <Route
              path="/time-tracker-status-details"
              element={<TimeTrackerStatusDetails />}
            />
            <Route
              path="/export-attendance-report"
              element={<ExportAttendanceReport />}
            />
            <Route path="/placement-details" element={<PlacementDetails />} />
            <Route path="/auth/signinwin/main" element={<CallbackPage />} />
            <Route path="/debug" element={<DebugPage />} />
          </Routes>
        </AnimatePresence>
      </div>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    user: state.oidc.user,
    isAuthenticated: state.oidc.user && !state.oidc.user.expired,
  };
}

export default connect(mapStateToProps)(App);
