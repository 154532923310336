import {createSlice} from "@reduxjs/toolkit";

import { ExportErrorInfo, ExportInfo, ExportAttendanceInitialState } from "../../types/constant";

const exportAttendanceInitialState : ExportAttendanceInitialState = {
    ExportReportError: {} as ExportErrorInfo,
    ExportReportData: {} as ExportInfo
}

export const ExportReportSlice = createSlice({
    name: "ExportReport",
    initialState: exportAttendanceInitialState,
    reducers: {
        setExportErrors: (state ,action) => {
            state.ExportReportError = action.payload;
            
        },
        setStartDate:(state,action) => {
            state.ExportReportData.StartDate = action.payload;
        },
        setEndDate:(state,action) => {
            state.ExportReportData.EndDate = action.payload;
        },
        setStartDateError:(state,action) => {
            state.ExportReportError.StartDate = action.payload;
        },
        setEndDateError:(state,action) => {
            state.ExportReportError.EndDate = action.payload;
        },
        setEmailError:(state,action) => {
            state.ExportReportError.EmailId = action.payload;
        }
    }
})

export const {setExportErrors, setStartDate, setEmailError, setStartDateError, setEndDate, setEndDateError} = ExportReportSlice.actions;
export const selectExportInfo = (state: any) => state.ExportReport;
export default ExportReportSlice.reducer;