import { createSlice } from "@reduxjs/toolkit";

const PlacementDetailInitialState = {
    placementDetailName: "",
    placementDetailStatus: "",
    placementDetailShifts: "",
    placementDetailLocation: "",
    placementDetailCheckin: "",
    placementDetailCheckout: ""
}

const PlacementDetailSlice = createSlice({
    name: "placementSlice",
    initialState: PlacementDetailInitialState,
    reducers: {
        setPlacementDetailName: (state, {payload}) => {
            state.placementDetailName = payload.placementDetailName;
            state.placementDetailStatus = payload.placementDetailStatus;
            state.placementDetailShifts = payload.placementDetailShifts;
            state.placementDetailLocation = payload.placementDetailLocation;
            state.placementDetailCheckin = payload.placementDetailCheckin;
            state.placementDetailCheckout = payload.placementDetailCheckout;

        }
    }
})

export const {setPlacementDetailName} = PlacementDetailSlice.actions;
export const getAllPlacementDetails = (state: any) => state.placementSlice;
export default PlacementDetailSlice.reducer;