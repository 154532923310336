import React from "react";
import "./Notifications.scss";
import { TagSkeleton } from '@tag/tag-components-react-v3';
import "./Notifications.scss";

export default function NotificationsSkeleton() {
    return (
        <>
            <div className="notification_header">
                <div className="notification_header_title">
                    <TagSkeleton
                        width='150px'
                        height='20px'>
                    </TagSkeleton>
                </div>

                <div className="notification_header_action">
                    <TagSkeleton
                        width='25px'
                        height='25px'>
                    </TagSkeleton>
                </div>

            </div>

            <div className="notification_content">
                {Array(5)
                    .fill(0)
                    .map((key, index) => (

                        <div className="notification_card" key={index}>
                            <div className="notification_card-icon">
                                <div className="notification_card-iconBg">
                                    <TagSkeleton
                                        width='25px'
                                        height='25px'>
                                    </TagSkeleton>
                                </div>
                            </div>
                            <div className="notification_card-title">
                                <TagSkeleton
                                    width='200px'
                                    height='42px'>
                                </TagSkeleton>
                            </div>
                            <div className="notification_card-date">
                                <div className="notification_card-dateText">
                                    <TagSkeleton
                                        width='60px'
                                        height='12px' style={{ marginBottom: "7px", display: "block" }}>
                                    </TagSkeleton>
                                </div>
                                <div className="notification_card-dots">
                                    <TagSkeleton
                                        width='25px'
                                        height='7px' style={{ float: "right" }}>
                                    </TagSkeleton>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    )
}
