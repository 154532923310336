import React, { useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import iconCancel from "../../images/iconCancel.svg";
import iconPlusCircle from "../../images/iconPlusCircle.svg";
import iconRightArrow from "../../images/iconRightArrow.png";

import "./EditTimesheet.scss";
import { useNavigate } from 'react-router-dom';
import { TagButton } from '@tag/tag-components-react-v3';
import AddTimeEntry from '../AddTimeEntry/AddTimeEntry';
import EditTimesheetSkeleton from "./EditTimesheetSkeleton";
import { getAllEditTimesheet } from "../../api/api";

export default function EditTimesheet() {
    const [open, setOpen] = React.useState(false);
    const [_showSkeleton, setShowSkeleton] = React.useState(true);
    const [_httpStatusCode, setHttpStatusCode] = React.useState(0);
    const [getEditTimesheet, setEditTimesheet] = useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSkeleton(false);
        }, 500);
        return () => clearTimeout(timer);
      }, []);

      useEffect(() => {
       
        getApi();
        
    }, []);


    async function getApi() {
        try {
            const res = await getAllEditTimesheet()
            if (Object.keys(res.data).length && res.status === 200) {
                // received api data successfully
                
                if (res.data) {
                    setLoading(false);
                }
                setEditTimesheet(res.data);
                setHttpStatusCode(res.status);
              }
            
            
        } catch (err) {
            console.log("err:", err);
            return [];
        }
    }
    const navigate = useNavigate();
    const onBackClick = () => {
        navigate("/timesheet");
    }
    const editEntry = () => {
        setOpen(true);
        
    }
    
    return (
        <>
            {loading ? <EditTimesheetSkeleton /> :
                <>
                    <div className="timeSheet-header">
                        <div className="timeSheet-header__back">
                            <RiArrowLeftSLine className="timeSheet-header__back-size" onClick={() => onBackClick()} />
                        </div>
                        <div className="timeSheet-header__title">
                            Edit timesheet
                        </div>
                        <div className="timeSheet-header__action timeSheet-header__close">
                            <img 
                                src={iconPlusCircle} 
                                alt="Add icon"  
                                onClick={() => editEntry()} className="timeSheet-header__action-size"  />
                        </div>
                        <div className="timeSheet-header__action timeSheet-header__close">
                            <img src={iconCancel} alt="Cancel icon"  className="timeSheet-header__action-size" onClick={() => navigate("/timesheet-details")} />
                        </div>
                    </div>
                    <div className="timeSheet-content" >
                        <table className="timeSheet-content__table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getEditTimesheet.map((item) => (
                                    <tr key={item.id} onClick={() => editEntry()}>
                                        <td data-label="Number">
                                            <div className="timeSheet-content__table-date">{item.date.date}</div> 
                                            <div className="timeSheet-content__table-day">{item.date.day}</div> 
                                        </td>
                                        <td data-label="Name">{item.startTime}</td>
                                        <td data-label="Phone">{item.endTime}</td>
                                        <td data-label="Date">
                                            <div className="timeSheet-content__table-total">{item.total}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="timeSheet-footer">
                        <div className="timeSheet-footer__total">
                            <div className="timeSheet-footer__total-text">
                                {/* Total: */}
                                Amended Total:
                            </div>    
                            <div className="timeSheet-footer__total-text">
                                {/* 27:30 */}
                                <span className="timeSheet-footer__total-text-preValue">27:30</span> <img src={iconRightArrow} alt="Arrow icon" className="timeSheet-footer__arrowIcon"/> 29:30
                            </div>    
                        </div>
                        <div className="timeSheet-footer-btnGrid">
                            <div className="timeSheet-footer-btn">
                                <TagButton
                                    className="customTernaryButton"
                                    text="Cancel"
                                    size="default"
                                    device="mobile"
                                    onButtonClick={() => navigate("/timesheet-details")}
                                    >
                                </TagButton>
                            </div>
                            <div className="timeSheet-footer-btn timeSheet-footer-btn__approve ">
                                <TagButton
                                    device="mobile"
                                    text="Approve"
                                    accent="keppel"
                                    className='customButton'
                                    onButtonClick={() => navigate("/timesheet")}
                                ></TagButton>
                            </div>
                        </div>
                    </div>
                </>
            }
            {open && <AddTimeEntry onDismiss={() => setOpen(false)}/>}
        </>
    )
}
