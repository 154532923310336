import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowBack from "../../images/angle_left.svg";
import iconCancel from "../../images/iconCancel.svg";
import iconPlus from "../../images/iconPlus.svg";
import iconMinus from "../../images/iconMinus.svg";
import iconPlusCircle from "../../images/iconPlusCircle.svg";
import "./AmendTimesheet.scss";
import pencil from "../../images/pencil.svg";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { TagButton, TagMobileSliderPopover } from "@tag/tag-components-react-v3";
import AmendTimesheetSkeleton from "./AmendTimesheetSkeleton";

export default function AmendTimesheet() {
	const navigate = useNavigate();
	const [showSkeleton, setShowSkeleton] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSkeleton(false);
		}, 500);
		return () => clearTimeout(timer);
	}, []);
	const onBackClick = () => {
		navigate('/timesheet')
	}
	const [editAmendOpen, setEditAmendOpen] = React.useState(false)
	const AmendTimesheetData = [
		{
			id: 1,
			date: {
				date: '17',
				day: 'Mon',
			},
			startTime: '00:00',
			endTime: '00:00',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 2,
			date: {
				date: '18',
				day: 'Tue',
			},
			startTime: '00:00',
			endTime: '+00:30',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 3,
			date: {
				date: '19',
				day: 'Wed',
			},
			startTime: '00:00',
			endTime: '17:30',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 4,
			date: {
				date: '20',
				day: 'Thu',
			},
			startTime: '00:00',
			endTime: '00:00',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 5,
			date: {
				date: '21',
				day: 'Fri',
			},
			startTime: '00:00',
			endTime: '00:00',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 6,
			date: {
				date: '22',
				day: 'Sat',
			},
			startTime: '00:00',
			endTime: '00:00',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
		{
			id: 7,
			date: {
				date: '23',
				day: 'Sun',
			},
			startTime: '00:00',
			endTime: '00:00',
			payRate: {
				time: '00.00',
				day: 'Per day',
			},
			total: '00:00',
		},
	]
	return (
		<>
		{showSkeleton ? (
				<AmendTimesheetSkeleton />
			) : (
				<>
			<div className="amend_header">
				<div className="amend_header_back">
					<img
						src={arrowBack}
						className="amend_back"
						alt={arrowBack}
						onClick={() => onBackClick()}
					/>
				</div>
				<div className="amend_header_title">Amend 21 timesheets</div>
				<Link to="">
					<div className="amend_header_action">
						<img
							src={iconPlusCircle}
							className="amend_header_action_size"
							alt={arrowBack}
						/>
					</div>
				</Link>
				<Link to="">
					<div className="amend_header_action">
						<img
							src={iconCancel}
							className="amend_header_action_size"
							alt={arrowBack}
						/>
					</div>
				</Link>
			</div>

			<div className="amend_content">
				<table className="amend_content_table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Start time</th>
							<th>End time</th>
							<th>Pay rate</th>
							<th>Total</th>
							<th>Edit</th>
						</tr>
					</thead>
					<tbody>
						{AmendTimesheetData.map((item) => (
							<tr key={item.id}>
								<td data-label="Number">
									<div className="amend_content_table_date">
										{item.date.date}
									</div>
									<div className="amend_content_table_day">{item.date.day}</div>
								</td>
								<td data-label="Name">
									<div className="amend_content_table_starttime">
										{item.startTime}
									</div>
								</td>
								<td data-label="Phone">
									<div className="amend_content_table_endtime">
										{item.endTime}
									</div>
								</td>
								<td data-label="Number">
									<div className="amend_content_table_ratings">
									<div className="amend_content_table_payrate">
										{item.payRate?.time}{' '}
									</div>
									<div className="amend_content_table_paydate">
										{item.payRate?.day}{' '}
									</div>
									</div>
								</td>
								<td data-label="Date">
									<div className="amend_content_table_total">{item.total}</div>
								</td>
								<td>
									<div
										className="amend_content_table_image"
										onClick={() => setEditAmendOpen(true)}
									>
										<img src={pencil} alt="edit icon"></img>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="amend_footer">
				<div className="amend_footer_total">
					<div className="amend_footer_total_text">Total</div>
					<div className="amend_footer_total_text">27:30</div>
				</div>
				<div className="amend_footer_btn_approve"  onClick={() => navigate("/timesheet")}>
					<TagButton
						className="customTernaryButton"
						text="Cancel"
						device="mobile"
					></TagButton>
				</div>
			</div>

			{/* Amend popup here */}
			<div className='amend_pophover_contents'>
				<TagMobileSliderPopover
					open={editAmendOpen}
					className='customPopover'
					heading="Edit time entry for 21 timesheets"
					initialHeight="600px"
					onClosed={() => setEditAmendOpen(false)}
				>
					<div className='amend_pophover'>
						<div className='amend_pophover_content'>
							<div className='amend_pophover_content_box'>
								<p className='amend_pophover_content_box_day'>17 Monday May</p>
								<div className='amend_pophover_content_box_content'>
									<p>Change start and end times by 15 minute intervals with the Plus and Minus. Tap the time to enter a time that will change all timesheets.</p>
									<div>
									<span>
										<AiOutlineClose style={{ fill: '#337EB7', fontSize: "20px" }} />

									</span>
									</div>
								</div>
							</div>

							<div className='amend_pophover_content_times'>
								<div className='amend_pophover_content_times_timings'>

									<div className="amend_pophover_content_times_timings_inputControl">
										<p className='amend_pophover_content_times_timings_label'>Start time</p>
										<div className="amend_pophover_content_times_timings_input">
											<input type="text" placeholder="00:00" className="amend_pophover_content_times_timings_inputBox  text-center" />
											<span className="amend_pophover_content_times_timings_actionIcon amend_pophover_content_times_timings_plusIcon">
												<img src={iconPlus} alt="Plus Icon" />
											</span>
											<span className="amend_pophover_content_times_timings_actionIcon">
												<img src={iconMinus} alt="Minus Icon" />
											</span>
										</div>
									</div>
								</div>

								<div className='amend_pophover_content_times_timings'>

									<div className="amend_pophover_content_times_timings_inputControl">
										<p className='amend_pophover_content_times_timings_label'>End time</p>
										<div className="amend_pophover_content_times_timings_input">
											<input type="text" placeholder="00:00" className="amend_pophover_content_times_timings_inputBox  text-center" />
											<span className="amend_pophover_content_times_timings_actionIcon amend_pophover_content_times_timings_plusIcon">
												<img src={iconPlus} alt="Plus Icon" />
											</span>
											<span className="amend_pophover_content_times_timings_actionIcon">
												<img src={iconMinus} alt="Minus Icon" />
											</span>
										</div>
									</div>
								</div>

								<div className='amend_pophover_content_times_timings'>
									<div className="amend_pophover_content_times_timings_inputControl">
										<p className='amend_pophover_content_times_timings_label'>Pay rate</p>

										<div className="amend_pophover_content_times_timings_input">
											<input type="text" placeholder="00:00" value="£ 12.31" className="amend_pophover_content_times_timings_inputBox" />

										</div>
									</div>
								</div>

								<div className='amend_pophover_content_times_timings'>
									<div className="amend_pophover_content_times_timings_inputControl">
										<div className="amend_pophover_content_times_timings_label">Add breaks</div>

										<div className="amend_pophover_content_times_timings_input">
											<input type="text" placeholder="00:00" className="amend_pophover_content_times_timings_inputBox " />
											<span className="amend_pophover_content_times_timings_actionIcon amend_pophover_content_times_timings_plusIcon">
												<img src={iconPlus} alt="Plus Icon" />
											</span>


										</div>
										<div className='amend_pophover_content_times_timings_break'>
											<p className='amend_pophover_content_times_timings_break_texts'>30 min break <AiFillCloseCircle style={{ marginLeft: "15px",marginTop: "1px", fontSize: "20px" }} /></p>
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="amend_pophover_footer">
							<div className="amend_pophover_footer_total">
								<div className="amend_pophover_footer_total_text">
									Total
								</div>
								<div className="amend_pophover_footer_total_text">
									27:30
								</div>
							</div>

							<div className="amend_pophover_footer_btnGrid">
								<div className="amend_pophover_footer_btn" onClick={() => setEditAmendOpen(false)}>
									<TagButton
										className="customTernaryButton"
										text="Cancel"
										size="default"
										device="mobile">
									</TagButton>
								</div>

								<div className="amend_pophover_footer_btn amend_pophover_footer_btn_filled "  onClick={() => setEditAmendOpen(false)}>
									<TagButton
										device="mobile"
										text="Amend time entry"
										accent="navy"
										className='customButton'
									></TagButton>
								</div>
							</div>
						</div>
					</div>
				</TagMobileSliderPopover>
			</div>
			</>
			)}
		</>
	)
}
