import './TimeTracker.scss'
import { TagSkeleton } from '@tag/tag-components-react-v3'

export default function TimeTrackerSkeleton() {
  return (
    <>
      <div className="time_tracker_header">
        <div className="time_tracker_header_title">
          <TagSkeleton width="150px" height="20px"></TagSkeleton>
        </div>
        <div className="time_tracker_header_icons">
          <div>
            {' '}
            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>
          <div>
            {' '}
            <TagSkeleton
              width="20px"
              height="20px"
              itemStyle={{ margin: '0 8px' }}
            ></TagSkeleton>
          </div>
          <div>
            {' '}
            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>
        </div>
      </div>

      <div className="time_tracker_filter">
        <div className="time_tracker_filter_time">
          <div className="time_tracker_filter_time_title">
            <TagSkeleton width="120px" height="15px"></TagSkeleton>
          </div>
          <div className="time_tracker_filter_time_shifts">
            <div className="time_tracker_filter_time_shifts_shift">
              {' '}
              <TagSkeleton width="70px" height="10px"></TagSkeleton>
            </div>
            <div className="time_tracker_filter_time_shifts_shift">
              <TagSkeleton width="60px" height="10px"></TagSkeleton>
            </div>
          </div>
        </div>
        <div className="time_tracker_filter_time_filters">
          <TagSkeleton width="80px" height="20px"></TagSkeleton>
        </div>
      </div>

      <section className="timetracker-main-card">
        <div className="time_tracker_infos">
          <div className="time_tracker_infos_box">
            <div className="time_tracker_infos_box_text">
              <TagSkeleton width="60px" height="10px"></TagSkeleton>
              <p>
                <TagSkeleton width="10px" height="10px"></TagSkeleton>
              </p>
            </div>

            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>

          <div className="time_tracker_infos_box">
            <div className="time_tracker_infos_box_text">
              <TagSkeleton width="60px" height="10px"></TagSkeleton>
              <p>
                <TagSkeleton width="10px" height="10px"></TagSkeleton>
              </p>
            </div>

            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>

          <div className="time_tracker_infos_box">
            <div className="time_tracker_infos_box_text">
              <TagSkeleton width="60px" height="10px"></TagSkeleton>
              <p>
                <TagSkeleton width="10px" height="10px"></TagSkeleton>
              </p>
            </div>

            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>

          <div className="time_tracker_infos_box">
            <div className="time_tracker_infos_box_text">
              <TagSkeleton width="60px" height="10px"></TagSkeleton>
              <p>
                <TagSkeleton width="10px" height="10px"></TagSkeleton>
              </p>
            </div>

            <TagSkeleton width="20px" height="20px"></TagSkeleton>
          </div>
        </div>

        {Array(5)
          .fill(0)
          .map((_key, dataIn) => (
            <div
              className="timetracker-main-card-content-pageTitle"
              key={dataIn}
            >
              <div className="timetracker-main-card-shifts">
                <div className="timetracker-main-card-shifts-time">
                  <b>
                    <TagSkeleton height="10px" width="120px"></TagSkeleton>
                  </b>
                  <span className="timetracker-main-card-shifts-count">
                    <TagSkeleton height="10px" width="50px"></TagSkeleton>
                  </span>
                </div>

                {Array(5)
                  .fill(0)
                  .map((_key, dataIn) => (
                    <div
                      className="timetracker-main-card-containerBox"
                      key={dataIn}
                    >
                      <div className="timetracker-main-cards 11">
                        <div className="timetracker-main-card-body">
                          <div className="timetracker-main-card-name">
                            <TagSkeleton
                              height="10px"
                              width="70px"
                            ></TagSkeleton>
                            <span>
                              <TagSkeleton
                                height="20px"
                                width="80px"
                              ></TagSkeleton>
                            </span>
                          </div>
                          <div className="timetracker-main-card-location">
                            <TagSkeleton
                              height="10px"
                              width="80px"
                            ></TagSkeleton>
                          </div>
                          <div className="timetracker-main-card-names">
                            <h5>
                              <TagSkeleton
                                height="10px"
                                width="100px"
                              ></TagSkeleton>
                            </h5>
                            <span className="timetracker-main-card-label">
                              <TagSkeleton
                                height="10px"
                                width="90px"
                              ></TagSkeleton>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </section>
    </>
  )
}
