import React, { useState } from 'react'

import backarrow from '../../images/angle_left.svg'
import addicon from '../../images/iconPlusCircle.svg'
import calender from '../../images/trackercalender.svg'
import dashboard from '../../images/dashboard.svg'
import usericon from '../../images/usericon.svg'
import price from '../../images/priceicon.svg'
import timecard from '../../images/time_card.svg'
import infoentity from '../../images/infoentity.svg'
import './PlacementDetails.scss'
import PageFooter from '../PageFooter/PageFooter'
import { useNavigate } from 'react-router-dom'
import {TagMobileAlert } from '@tag/tag-components-react-v3'
import { useSelector } from 'react-redux'
import { getAllPlacementDetails } from './PlacementDetailsSlice'
import ManualWorkerCheckIn from '../ManualWorkerCheckIn/ManualWorkerCheckIn'
export default function PlacementDetails() {

	const navigate = useNavigate()
	const onBackClick = () => {
		navigate('/time-tracker')
	}

	const { placementDetailName,placementDetailStatus,placementDetailShifts,placementDetailLocation,placementDetailCheckin,placementDetailCheckout } = useSelector(getAllPlacementDetails);
	const isShowTimesheetSubmit = false;

	 const [openmanualWorkerCheckIn, setmanualWorkerCheckIn] = React.useState(false);
	 const manualWorkerCheckIn = () => {
		
        setmanualWorkerCheckIn(true);
		setSelectApp(true);
		
    }
	const [openSelectApp, setSelectApp] = React.useState(false);
	const selectApplicatin = () => {
        setSelectApp(true);
		setmanualWorkerCheckIn(true);
		
    }
	return (
		<>
			<div className="placement_details_header">
				<div className="placement_details_header_title" data-testid="headTitle" > 
					<img
						src={backarrow}
						alt="Go back arrow"
						onClick={() => onBackClick()}
					/>
					Placement details
				</div>
				
				<div className={`placement_details_header_addicon ${isShowTimesheetSubmit ? "placement_details_header_addicon_hidden" : ""}`}>
					<img data-testid="manualcheckin" src={addicon} alt="addicon"  onClick={(e) => {if(!isShowTimesheetSubmit) manualWorkerCheckIn()}}/>
				</div>
		
			</div>

			<div className="placement_detail">
				{/* //TOAST MESSAGE */}
				{isShowTimesheetSubmit ? 
						<div className="placement_detail_toastmessage">
							<TagMobileAlert 
								
								closeTransition="none"
							>
								<div style={{display: "flex", alignItems:"center"}}><img src={infoentity} style={{marginRight:"16px"}}/><span>Timesheet has been submitted</span></div>
							</TagMobileAlert>
						</div> 
					: "" }
				<div className={`placement_detail_info ${isShowTimesheetSubmit ? "placement_detail_info_hidden" : ""}`}>
					<div className="placement_detail_info_shift">
						<div className="placement_detail_info_shift_time">
							<p>Shift time:</p>
							<h5>{placementDetailShifts}</h5>
						</div>
						<div className="placement_detail_info_shift_date">
							<p>
								<img src={calender} alt="calendericon" />
								<span>15/03/2022</span>
							</p>
							<p>
								<img src={price} alt="priceicon" />
								<span>{placementDetailLocation}</span>
							</p>
						</div>
					</div>
				</div>
				<div className="placement_detail_user">
					<div className="placement_detail_user_info">
						<p>
							<img src={usericon} alt="usericon" />
							<span>{placementDetailName}</span>
						</p>
						<p className='placement_detail_user_info_status'>
							<img src={dashboard} alt="dashboard" />
							Placement status:
							<span
							className={
								placementDetailStatus === 'Completed'
									? 'placement_detail_user_info_status_completed'
									: placementDetailStatus === 'In Progress'
										? 'placement_detail_user_info_status_inprogress'
										: placementDetailStatus === 'No Show'
											? 'placement_detail_user_info_status_noshow'
											: 'placement_detail_user_info_status_scheduled'
							}>
							{placementDetailStatus}
							</span>
						</p>
						<p>
							<img src={timecard} alt="timecard" />
							<span>Check in: {placementDetailCheckin} - Check out: {placementDetailCheckout}</span>
						</p>
						<p>
							<img src={timecard} alt="timecard" />
							<span>Break: 00:00</span>
						</p>
					</div>
				</div>
			</div>
			<PageFooter />
			{openmanualWorkerCheckIn ? <ManualWorkerCheckIn selectApplicatin={() => selectApplicatin()} onDismiss={() => setmanualWorkerCheckIn(false)}/> : null} 
			
			
			
		</>
	)
}
